import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import ContactContent from '../components/About/ContactContent';

export const ContactPageTemplate = ({
  prompt,
  team,
  seminole,
  orange,
  lake,
  volusia
}) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <Header title="Contact Us" />
      <ContactContent
        prompt={prompt}
        team={team}
        seminole={seminole}
        orange={orange}
        lake={lake}
        volusia={volusia}
      />
    </div>
  );
};

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ContactPageTemplate
        prompt={frontmatter.prompt}
        team={frontmatter.team}
        seminole={frontmatter.seminole}
        orange={frontmatter.orange}
        lake={frontmatter.lake}
        volusia={frontmatter.volusia}
      />
    </Layout>
  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        prompt
        team {
          name
          title
          email
        }
        seminole {
          name
          person
          email
        }
        orange {
          name
          person
          email
        }
        lake {
          name
          person
          email
        }
        volusia {
          name
          person
          email
        }
      }
    }
  }
`;
