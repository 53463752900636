import React from 'react';

export const Section = ({ children, spotlight = false }) => {
  return (
    <div className="w-full flex flex-col py-12 items-center justify-center bg-white px-4 md:px-8 lg:px-24 xl:px-36">
      {children}
    </div>
  );
};

export const SubSection = ({ children }) => {
  return (
    <div className="w-full flex flex-col justify-center items-start py-8">
      {children}
    </div>
  );
};

export default Section;
