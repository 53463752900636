import React from 'react';
import GoogleMapReact from 'google-map-react';
import {
  seminoleCounty,
  orangeCounty,
  lakeCounty,
  volusiaCounty
} from './countyCoordinates';
import Section from '../Section';

const infoContent = ({ name, person, email }) => {
  return `
    <div class="flex flex-col items-start space-y-2 justify-center">
     <div class="w-full flex items-center justify-center">
       <p class="text-2xl font-baloo text-s4tk-orange text-center w-full">${name}</p>
     </div>
     <div class="w-full flex items-center justify-center">
       <p class="text-xl font-baloo text-black text-center w-full">${person}</p>
     </div>
     <div class="w-full flex items-center justify-center">
       <a href="mailto:${email}" class="text-xl font-baloo text-s4tk-blue text-center w-full underline">${email}</a>
     </div>
    </div>
 `;
};

export const ContactContent = ({
  prompt,
  team,
  seminole,
  orange,
  lake,
  volusia
}) => {
  const handleApiLoaded = (map, maps) => {
    const seminolePolygon = new maps.Polygon({
      paths: seminoleCounty,
      strokeColor: '#005ED2',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#005ED2',
      fillOpacity: 0.35
    });
    seminolePolygon.setMap(map);

    const orangePolygon = new maps.Polygon({
      paths: orangeCounty,
      strokeColor: '#EC8D39',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#EC8D39',
      fillOpacity: 0.35
    });
    orangePolygon.setMap(map);

    const lakePolygon = new maps.Polygon({
      paths: lakeCounty,
      strokeColor: '#1BC65D',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#1BC65D',
      fillOpacity: 0.35
    });
    lakePolygon.setMap(map);

    const volusiaPolygon = new maps.Polygon({
      paths: volusiaCounty,
      strokeColor: '#ffff00',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#ffff00',
      fillOpacity: 0.35
    });
    volusiaPolygon.setMap(map);

    var infoWindow = new maps.InfoWindow();
    maps.event.addListener(seminolePolygon, 'mouseover', function (e) {
      infoWindow.setContent(infoContent(seminole));
      var latLng = e.latLng;
      infoWindow.setPosition(latLng);
      infoWindow.open(map);
    });

    infoWindow = new maps.InfoWindow();
    maps.event.addListener(orangePolygon, 'mouseover', function (e) {
      infoWindow.setContent(infoContent(orange));
      var latLng = e.latLng;
      infoWindow.setPosition(latLng);
      infoWindow.open(map);
    });

    infoWindow = new maps.InfoWindow();
    maps.event.addListener(lakePolygon, 'mouseover', function (e) {
      infoWindow.setContent(infoContent(lake));
      var latLng = e.latLng;
      infoWindow.setPosition(latLng);
      infoWindow.open(map);
    });

    infoWindow = new maps.InfoWindow();
    maps.event.addListener(volusiaPolygon, 'mouseover', function (e) {
      infoWindow.setContent(infoContent(volusia));
      var latLng = e.latLng;
      infoWindow.setPosition(latLng);
      infoWindow.open(map);
    });
  };

  return (
    <Section>
      <p className="font-baloo text-4xl text-s4tk-blue mb-8 text-left sm:text-justify">
        {prompt}
      </p>
      <div className="w-full h-112 border-4 border-s4tk-blue rounded-md mb-16">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCFoEssSpwt_nUv3CpCoOKKXdKU6n0oc5E' }}
          defaultCenter={{ lat: 28.8029, lng: -81.2695 }}
          defaultZoom={9}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        />
      </div>
      <div className="w-full flex flex-row flex-wrap items-center justify-center">
        {team.map((item, index) => (
          <div
            key={index}
            className="w-96 m-8 flex flex-col items-center space-y-2"
          >
            <p className="text-2xl font-baloo text-center font-bold text-s4tk-blue">
              {item.name}
            </p>
            <p className="text-xl font-baloo text-center text-s4tk-blue">
              {item.title}
            </p>
            <p className="text-xl font-baloo text-center text-s4tk-blue">
              {item.email}
            </p>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default ContactContent;
