import React from 'react';

import headerBg from '../img/bgpattern.svg';

export const Header = ({ title }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${headerBg})`
      }}
      className="w-full h-36 relative flex items-center justify-center"
    >
      <div className="absolute top-0 left-0 w-full h-full z-10 bg-s4tk-blue opacity-90" />
      <p className="font-baloo font-bold text-white text-5xl z-20 text-center px-4">
        {title}
      </p>
    </div>
  );
};

export default Header;
