export const seminoleCounty = [
  { lng: -81.4137802124023, lat: 28.785852432251 },
  { lng: -81.4172973632812, lat: 28.8066005706788 },
  { lng: -81.4200592041014, lat: 28.8181495666504 },
  { lng: -81.4134902954102, lat: 28.8290081024171 },
  { lng: -81.4049530029296, lat: 28.8361511230469 },
  { lng: -81.3932571411132, lat: 28.8441410064698 },
  { lng: -81.3836288452147, lat: 28.8526306152344 },
  { lng: -81.3792572021484, lat: 28.859411239624 },
  { lng: -81.3759536743164, lat: 28.8652992248536 },
  { lng: -81.3737792968749, lat: 28.8684597015382 },
  { lng: -81.3695068359374, lat: 28.8715705871582 },
  { lng: -81.367202758789, lat: 28.8793201446533 },
  { lng: -81.3617095947266, lat: 28.8718395233155 },
  { lng: -81.363899230957, lat: 28.8682212829591 },
  { lng: -81.3634872436523, lat: 28.8645305633545 },
  { lng: -81.3541870117188, lat: 28.8629302978516 },
  { lng: -81.3532562255859, lat: 28.8592319488527 },
  { lng: -81.3555068969727, lat: 28.8537807464601 },
  { lng: -81.359275817871, lat: 28.849739074707 },
  { lng: -81.3484573364258, lat: 28.8467082977295 },
  { lng: -81.3396530151367, lat: 28.8455810546876 },
  { lng: -81.3325424194336, lat: 28.8403491973878 },
  { lng: -81.3243026733398, lat: 28.8378505706787 },
  { lng: -81.3207778930663, lat: 28.8340911865234 },
  { lng: -81.2267227172851, lat: 28.832618713379 },
  { lng: -81.2222671508788, lat: 28.8256206512451 },
  { lng: -81.2167358398436, lat: 28.8199691772461 },
  { lng: -81.2127532958984, lat: 28.8148097991944 },
  { lng: -81.2134628295898, lat: 28.8093204498291 },
  { lng: -81.2094573974609, lat: 28.8041610717773 },
  { lng: -81.2003173828124, lat: 28.7979526519776 },
  { lng: -81.1930923461914, lat: 28.7963905334473 },
  { lng: -81.1874008178711, lat: 28.7957801818849 },
  { lng: -81.1816406249999, lat: 28.7970104217529 },
  { lng: -81.1718368530273, lat: 28.7949104309082 },
  { lng: -81.164093017578, lat: 28.7933311462402 },
  { lng: -81.1547775268555, lat: 28.7921695709229 },
  { lng: -81.1428375244141, lat: 28.7918491363526 },
  { lng: -81.136962890625, lat: 28.7967491149902 },
  { lng: -81.1300582885742, lat: 28.8006992340089 },
  { lng: -81.1287460327148, lat: 28.8084697723389 },
  { lng: -81.1238784790039, lat: 28.8143100738525 },
  { lng: -81.1246566772461, lat: 28.8216800689698 },
  { lng: -81.1182861328124, lat: 28.8256416320801 },
  { lng: -81.1072616577148, lat: 28.8285598754883 },
  { lng: -81.0991058349609, lat: 28.8237495422364 },
  { lng: -81.0913391113281, lat: 28.8230781555175 },
  { lng: -81.0811767578125, lat: 28.8159103393556 },
  { lng: -81.076431274414, lat: 28.8180809020997 },
  { lng: -81.0713729858398, lat: 28.8142700195312 },
  { lng: -81.0668640136719, lat: 28.8095512390137 },
  { lng: -81.0670928955078, lat: 28.8026695251464 },
  { lng: -81.0590896606444, lat: 28.7937183380127 },
  { lng: -81.0537567138672, lat: 28.7825508117676 },
  { lng: -81.0611190795898, lat: 28.7506008148194 },
  { lng: -81.05322265625, lat: 28.7389011383058 },
  { lng: -81.0420608520508, lat: 28.7312412261964 },
  { lng: -81.0416717529297, lat: 28.7275600433349 },
  { lng: -81.0377502441406, lat: 28.7210197448732 },
  { lng: -81.0342330932617, lat: 28.717700958252 },
  { lng: -81.0307769775391, lat: 28.7125606536865 },
  { lng: -81.022850036621, lat: 28.7017707824706 },
  { lng: -81.0184173583984, lat: 28.6947612762451 },
  { lng: -81.0165023803711, lat: 28.6905708312988 },
  { lng: -81.020866394043, lat: 28.6842594146729 },
  { lng: -81.0211563110352, lat: 28.6764507293701 },
  { lng: -81.0189208984374, lat: 28.6662807464601 },
  { lng: -81.0134658813477, lat: 28.6587810516358 },
  { lng: -81.0061721801758, lat: 28.6452484130859 },
  { lng: -81.0070266723633, lat: 28.6356296539307 },
  { lng: -81.0015258789062, lat: 28.6299610137941 },
  { lng: -80.9955368041992, lat: 28.6233615875244 },
  { lng: -80.989990234375, lat: 28.619068145752 },
  { lng: -80.9876174926758, lat: 28.6130294799805 },
  { lng: -81.3279190063476, lat: 28.6101016998292 },
  { lng: -81.3285064697264, lat: 28.6238994598389 },
  { lng: -81.3290405273437, lat: 28.6399898529054 },
  { lng: -81.3694763183593, lat: 28.6405296325685 },
  { lng: -81.4576721191406, lat: 28.6403617858887 },
  { lng: -81.4597473144531, lat: 28.6404094696045 },
  { lng: -81.4599990844726, lat: 28.6491508483887 },
  { lng: -81.459098815918, lat: 28.7134399414064 },
  { lng: -81.4481430053711, lat: 28.7154808044435 },
  { lng: -81.4418792724609, lat: 28.7162494659424 },
  { lng: -81.437629699707, lat: 28.7193603515626 },
  { lng: -81.4301528930663, lat: 28.7265300750733 },
  { lng: -81.419792175293, lat: 28.7423610687256 },
  { lng: -81.4173507690429, lat: 28.7542400360109 },
  { lng: -81.4158172607422, lat: 28.7702808380128 },
  { lng: -81.4137802124023, lat: 28.785852432251 }
];

export const orangeCounty = [
  { lng: -80.863182067871, lat: 28.3474197387696 },
  { lng: -81.2839965820312, lat: 28.3479194641114 },
  { lng: -81.5812530517578, lat: 28.347240447998 },
  { lng: -81.6577529907227, lat: 28.3471107482911 },
  { lng: -81.6574783325195, lat: 28.375608444214 },
  { lng: -81.657112121582, lat: 28.5378494262695 },
  { lng: -81.6567611694335, lat: 28.5690898895264 },
  { lng: -81.6525726318359, lat: 28.5708293914795 },
  { lng: -81.6518173217773, lat: 28.5790901184083 },
  { lng: -81.6475219726562, lat: 28.5845108032228 },
  { lng: -81.6530532836914, lat: 28.5905990600587 },
  { lng: -81.6571502685547, lat: 28.5925312042237 },
  { lng: -81.6575927734374, lat: 28.5952911376953 },
  { lng: -81.658576965332, lat: 28.6899509429932 },
  { lng: -81.6585159301758, lat: 28.7666606903076 },
  { lng: -81.6543731689452, lat: 28.7665691375733 },
  { lng: -81.6486206054687, lat: 28.7678203582764 },
  { lng: -81.6464767456055, lat: 28.7700710296632 },
  { lng: -81.6447830200195, lat: 28.7755393981935 },
  { lng: -81.6461486816406, lat: 28.7819995880128 },
  { lng: -81.6481704711913, lat: 28.7838802337646 },
  { lng: -81.6465682983398, lat: 28.7856807708741 },
  { lng: -81.5431518554687, lat: 28.7861328125001 },
  { lng: -81.5348358154297, lat: 28.7859401702881 },
  { lng: -81.5332870483398, lat: 28.7858982086182 },
  { lng: -81.5291442871093, lat: 28.7858009338379 },
  { lng: -81.4137802124023, lat: 28.785852432251 },
  { lng: -81.4158172607422, lat: 28.7702808380128 },
  { lng: -81.4173507690429, lat: 28.7542400360109 },
  { lng: -81.419792175293, lat: 28.7423610687256 },
  { lng: -81.4301528930663, lat: 28.7265300750733 },
  { lng: -81.437629699707, lat: 28.7193603515626 },
  { lng: -81.4418792724609, lat: 28.7162494659424 },
  { lng: -81.4481430053711, lat: 28.7154808044435 },
  { lng: -81.459098815918, lat: 28.7134399414064 },
  { lng: -81.4599990844726, lat: 28.6491508483887 },
  { lng: -81.4597473144531, lat: 28.6404094696045 },
  { lng: -81.4576721191406, lat: 28.6403617858887 },
  { lng: -81.3694763183593, lat: 28.6405296325685 },
  { lng: -81.3290405273437, lat: 28.6399898529054 },
  { lng: -81.3285064697264, lat: 28.6238994598389 },
  { lng: -81.3279190063476, lat: 28.6101016998292 },
  { lng: -80.9876174926758, lat: 28.6130294799805 },
  { lng: -80.984016418457, lat: 28.6120109558106 },
  { lng: -80.9779205322265, lat: 28.6086196899414 },
  { lng: -80.9753417968749, lat: 28.6080799102783 },
  { lng: -80.9742431640625, lat: 28.6098918914795 },
  { lng: -80.9721527099609, lat: 28.6102905273439 },
  { lng: -80.9680862426757, lat: 28.6078815460205 },
  { lng: -80.9640274047852, lat: 28.6054725646973 },
  { lng: -80.9522476196288, lat: 28.6014595031738 },
  { lng: -80.9492416381836, lat: 28.5986099243165 },
  { lng: -80.9453430175781, lat: 28.591609954834 },
  { lng: -80.9466323852538, lat: 28.5847511291504 },
  { lng: -80.9397277832031, lat: 28.5748996734619 },
  { lng: -80.9347457885742, lat: 28.5692481994629 },
  { lng: -80.9318313598633, lat: 28.5641098022462 },
  { lng: -80.9331283569336, lat: 28.5567893981935 },
  { lng: -80.9329299926758, lat: 28.5480499267579 },
  { lng: -80.934700012207, lat: 28.5421295166016 },
  { lng: -80.9394302368164, lat: 28.5404300689698 },
  { lng: -80.9385604858398, lat: 28.535800933838 },
  { lng: -80.9339675903319, lat: 28.5338325500489 },
  { lng: -80.9298095703125, lat: 28.5341796875001 },
  { lng: -80.9236373901367, lat: 28.5326194763184 },
  { lng: -80.9207458496094, lat: 28.5270195007325 },
  { lng: -80.9187927246093, lat: 28.5237503051758 },
  { lng: -80.9130859374999, lat: 28.5240402221681 },
  { lng: -80.9100570678711, lat: 28.5216598510742 },
  { lng: -80.9065933227538, lat: 28.5174198150636 },
  { lng: -80.90576171875, lat: 28.5118808746339 },
  { lng: -80.9016494750977, lat: 28.5108394622804 },
  { lng: -80.8949279785156, lat: 28.5106506347657 },
  { lng: -80.8912506103516, lat: 28.5119209289551 },
  { lng: -80.8861618041992, lat: 28.5094699859619 },
  { lng: -80.8852310180663, lat: 28.5066928863526 },
  { lng: -80.8807067871094, lat: 28.5028800964355 },
  { lng: -80.8803787231445, lat: 28.4978103637695 },
  { lng: -80.8840560913085, lat: 28.4960803985597 },
  { lng: -80.8821563720703, lat: 28.4914302825927 },
  { lng: -80.8780975341797, lat: 28.4894714355469 },
  { lng: -80.8746795654297, lat: 28.4838504791261 },
  { lng: -80.874397277832, lat: 28.4774112701415 },
  { lng: -80.8710021972656, lat: 28.4713306427002 },
  { lng: -80.8808975219727, lat: 28.4697895050048 },
  { lng: -80.8902893066405, lat: 28.467758178711 },
  { lng: -80.8961563110352, lat: 28.4628715515137 },
  { lng: -80.8985366821289, lat: 28.4546699523926 },
  { lng: -80.8930969238281, lat: 28.4476127624512 },
  { lng: -80.8960266113281, lat: 28.4384994506836 },
  { lng: -80.8993606567383, lat: 28.43217086792 },
  { lng: -80.8970184326172, lat: 28.4256610870362 },
  { lng: -80.8946990966797, lat: 28.4182415008545 },
  { lng: -80.8881530761719, lat: 28.4134502410889 },
  { lng: -80.8847579956054, lat: 28.4073696136475 },
  { lng: -80.8859405517578, lat: 28.4032707214355 },
  { lng: -80.8867263793945, lat: 28.395929336548 },
  { lng: -80.888931274414, lat: 28.3923206329347 },
  { lng: -80.8876190185546, lat: 28.3858413696289 },
  { lng: -80.8805694580078, lat: 28.3805809020996 },
  { lng: -80.8807830810547, lat: 28.375072479248 },
  { lng: -80.8756790161132, lat: 28.3730812072755 },
  { lng: -80.8727111816406, lat: 28.3693103790283 },
  { lng: -80.8744506835937, lat: 28.3643112182617 },
  { lng: -80.8701629638671, lat: 28.3545207977295 },
  { lng: -80.863182067871, lat: 28.3474197387696 }
];

export const lakeCounty = [
  { lng: -81.4137802124023, lat: 28.785852432251 },
  { lng: -81.5291442871093, lat: 28.7858009338379 },
  { lng: -81.5332870483398, lat: 28.7858982086182 },
  { lng: -81.5348358154297, lat: 28.7859401702881 },
  { lng: -81.5431518554687, lat: 28.7861328125001 },
  { lng: -81.6465682983398, lat: 28.7856807708741 },
  { lng: -81.6481704711913, lat: 28.7838802337646 },
  { lng: -81.6461486816406, lat: 28.7819995880128 },
  { lng: -81.6447830200195, lat: 28.7755393981935 },
  { lng: -81.6464767456055, lat: 28.7700710296632 },
  { lng: -81.6486206054687, lat: 28.7678203582764 },
  { lng: -81.6543731689452, lat: 28.7665691375733 },
  { lng: -81.6585159301758, lat: 28.7666606903076 },
  { lng: -81.658576965332, lat: 28.6899509429932 },
  { lng: -81.6575927734374, lat: 28.5952911376953 },
  { lng: -81.6571502685547, lat: 28.5925312042237 },
  { lng: -81.6530532836914, lat: 28.5905990600587 },
  { lng: -81.6475219726562, lat: 28.5845108032228 },
  { lng: -81.6518173217773, lat: 28.5790901184083 },
  { lng: -81.6525726318359, lat: 28.5708293914795 },
  { lng: -81.6567611694335, lat: 28.5690898895264 },
  { lng: -81.657112121582, lat: 28.5378494262695 },
  { lng: -81.6574783325195, lat: 28.375608444214 },
  { lng: -81.6577529907227, lat: 28.3471107482911 },
  { lng: -81.7911376953125, lat: 28.346269607544 },
  { lng: -81.7907333374023, lat: 28.3618907928468 },
  { lng: -81.8579330444336, lat: 28.3618812561035 },
  { lng: -81.8578109741211, lat: 28.3462505340577 },
  { lng: -81.9581069946288, lat: 28.3449897766114 },
  { lng: -81.9573669433594, lat: 28.3753204345703 },
  { lng: -81.9564361572266, lat: 28.4139194488525 },
  { lng: -81.9538192749023, lat: 28.5861892700196 },
  { lng: -81.9547119140625, lat: 28.6560401916504 },
  { lng: -81.9538497924805, lat: 28.7556896209717 },
  { lng: -81.9538269042968, lat: 28.9058303833008 },
  { lng: -81.9537658691406, lat: 28.9085903167725 },
  { lng: -81.9541091918945, lat: 28.9159393310547 },
  { lng: -81.954002380371, lat: 28.9200706481934 },
  { lng: -81.9540710449219, lat: 28.9599990844727 },
  { lng: -81.9504318237305, lat: 28.9599380493164 },
  { lng: -81.9379272460938, lat: 28.9601612091064 },
  { lng: -81.8551559448242, lat: 28.9594593048096 },
  { lng: -81.842658996582, lat: 28.9596691131593 },
  { lng: -81.8421020507812, lat: 28.9610404968262 },
  { lng: -81.7385025024414, lat: 28.9607505798341 },
  { lng: -81.731719970703, lat: 28.9610729217529 },
  { lng: -81.678108215332, lat: 28.9603805541993 },
  { lng: -81.6655960083007, lat: 28.9605693817139 },
  { lng: -81.658836364746, lat: 28.9604206085205 },
  { lng: -81.6591415405272, lat: 28.9682426452637 },
  { lng: -81.6591567993164, lat: 29.0049610137941 },
  { lng: -81.6589431762695, lat: 29.0127506256105 },
  { lng: -81.6595230102538, lat: 29.0476512908936 },
  { lng: -81.6417999267578, lat: 29.0477199554444 },
  { lng: -81.6386795043945, lat: 29.0476512908936 },
  { lng: -81.640998840332, lat: 29.1316795349121 },
  { lng: -81.641487121582, lat: 29.2252883911133 },
  { lng: -81.6414489746094, lat: 29.2454681396484 },
  { lng: -81.6415100097656, lat: 29.280330657959 },
  { lng: -81.6413345336914, lat: 29.2800903320313 },
  { lng: -81.6413345336914, lat: 29.3281326293946 },
  { lng: -81.581314086914, lat: 29.2215690612793 },
  { lng: -81.5588073730468, lat: 29.1981220245362 },
  { lng: -81.5448608398437, lat: 29.1870498657228 },
  { lng: -81.5449600219726, lat: 29.1864395141602 },
  { lng: -81.533576965332, lat: 29.1825103759765 },
  { lng: -81.5301208496094, lat: 29.1760101318361 },
  { lng: -81.5239715576171, lat: 29.1722011566162 },
  { lng: -81.5202178955078, lat: 29.1578903198243 },
  { lng: -81.5137557983398, lat: 29.147180557251 },
  { lng: -81.5093460083008, lat: 29.1374511718751 },
  { lng: -81.5029525756836, lat: 29.1244506835938 },
  { lng: -81.5017700195312, lat: 29.1115703582764 },
  { lng: -81.4983673095703, lat: 29.1032428741455 },
  { lng: -81.497428894043, lat: 29.1000003814697 },
  { lng: -81.4948501586914, lat: 29.0990219116211 },
  { lng: -81.4938201904297, lat: 29.0985412597657 },
  { lng: -81.4844970703124, lat: 29.0960311889649 },
  { lng: -81.4803619384766, lat: 29.095012664795 },
  { lng: -81.4667358398438, lat: 29.0969905853273 },
  { lng: -81.456901550293, lat: 29.0949306488037 },
  { lng: -81.4524459838867, lat: 29.0870208740234 },
  { lng: -81.4547424316406, lat: 29.0801906585694 },
  { lng: -81.453987121582, lat: 29.0705299377442 },
  { lng: -81.4453964233398, lat: 29.0611515045167 },
  { lng: -81.4367294311523, lat: 29.0549812316896 },
  { lng: -81.4307327270507, lat: 29.0465793609619 },
  { lng: -81.4230194091797, lat: 29.0431804656984 },
  { lng: -81.41845703125, lat: 29.0389404296875 },
  { lng: -81.4096069335938, lat: 29.0387306213379 },
  { lng: -81.4018859863281, lat: 29.0353298187256 },
  { lng: -81.3994827270508, lat: 29.0293006896973 },
  { lng: -81.394889831543, lat: 29.0259780883789 },
  { lng: -81.3929367065429, lat: 29.0218009948732 },
  { lng: -81.3869705200195, lat: 29.0124797821046 },
  { lng: -81.3824005126953, lat: 29.0086898803712 },
  { lng: -81.3794403076172, lat: 29.0035705566407 },
  { lng: -81.3760528564453, lat: 28.9952297210693 },
  { lng: -81.3729858398438, lat: 28.9933204650879 },
  { lng: -81.3656997680663, lat: 28.9931411743164 },
  { lng: -81.3580169677734, lat: 28.9888191223144 },
  { lng: -81.3555831909179, lat: 28.9837093353273 },
  { lng: -81.3594131469727, lat: 28.9778308868409 },
  { lng: -81.3642959594727, lat: 28.9715309143066 },
  { lng: -81.367546081543, lat: 28.9674816131593 },
  { lng: -81.3661880493164, lat: 28.9610195159912 },
  { lng: -81.365837097168, lat: 28.9554996490479 },
  { lng: -81.3606262207031, lat: 28.9558296203613 },
  { lng: -81.3566360473632, lat: 28.9502201080323 },
  { lng: -81.3548431396484, lat: 28.9409980773926 },
  { lng: -81.3535232543945, lat: 28.9336185455323 },
  { lng: -81.3527679443359, lat: 28.9244194030762 },
  { lng: -81.3550033569335, lat: 28.9194202423096 },
  { lng: -81.3583297729492, lat: 28.9130802154541 },
  { lng: -81.358039855957, lat: 28.905731201172 },
  { lng: -81.3556976318359, lat: 28.8973999023438 },
  { lng: -81.3559570312499, lat: 28.8891410827637 },
  { lng: -81.3596115112305, lat: 28.8887805938722 },
  { lng: -81.3642959594727, lat: 28.8884296417237 },
  { lng: -81.3659820556639, lat: 28.8847999572754 },
  { lng: -81.367202758789, lat: 28.8793201446533 },
  { lng: -81.3695068359374, lat: 28.8715705871582 },
  { lng: -81.3737792968749, lat: 28.8684597015382 },
  { lng: -81.3759536743164, lat: 28.8652992248536 },
  { lng: -81.3792572021484, lat: 28.859411239624 },
  { lng: -81.3836288452147, lat: 28.8526306152344 },
  { lng: -81.3932571411132, lat: 28.8441410064698 },
  { lng: -81.4049530029296, lat: 28.8361511230469 },
  { lng: -81.4134902954102, lat: 28.8290081024171 },
  { lng: -81.4200592041014, lat: 28.8181495666504 },
  { lng: -81.4172973632812, lat: 28.8066005706788 },
  { lng: -81.4137802124023, lat: 28.785852432251 }
];

export const volusiaCounty = [
  { lng: -81.1083755493163, lat: 29.4269886016846 },
  { lng: -81.1083297729492, lat: 29.4266662597656 },
  { lng: -81.1080551147461, lat: 29.426389694214 },
  { lng: -81.1080551147461, lat: 29.4255561828614 },
  { lng: -81.1083297729492, lat: 29.4252777099611 },
  { lng: -81.1086120605469, lat: 29.4252777099611 },
  { lng: -81.1091690063477, lat: 29.4247226715088 },
  { lng: -81.1086120605469, lat: 29.4241657257081 },
  { lng: -81.1080551147461, lat: 29.4241657257081 },
  { lng: -81.1074981689453, lat: 29.4236106872559 },
  { lng: -81.1074981689453, lat: 29.4233341217041 },
  { lng: -81.1072235107421, lat: 29.4230556488038 },
  { lng: -81.1074981689453, lat: 29.4227771759034 },
  { lng: -81.1074981689453, lat: 29.4225006103516 },
  { lng: -81.1072235107421, lat: 29.4222221374512 },
  { lng: -81.1072235107421, lat: 29.421667098999 },
  { lng: -81.1069412231445, lat: 29.4213886260986 },
  { lng: -81.1069412231445, lat: 29.421112060547 },
  { lng: -81.1066665649413, lat: 29.4208316802979 },
  { lng: -81.1066665649413, lat: 29.4194450378419 },
  { lng: -81.1063919067382, lat: 29.4191665649415 },
  { lng: -81.1063919067382, lat: 29.4180564880372 },
  { lng: -81.1061096191405, lat: 29.4177780151368 },
  { lng: -81.1061096191405, lat: 29.417221069336 },
  { lng: -81.1058349609375, lat: 29.4169445037842 },
  { lng: -81.1058349609375, lat: 29.4161090850831 },
  { lng: -81.1055526733398, lat: 29.4158325195312 },
  { lng: -81.1055526733398, lat: 29.4150009155275 },
  { lng: -81.1052780151367, lat: 29.4147224426271 },
  { lng: -81.1052780151367, lat: 29.4116668701172 },
  { lng: -81.1050033569336, lat: 29.4113883972169 },
  { lng: -81.1050033569336, lat: 29.411111831665 },
  { lng: -81.1047210693359, lat: 29.4108333587646 },
  { lng: -81.1041641235351, lat: 29.4108333587646 },
  { lng: -81.103889465332, lat: 29.4105567932129 },
  { lng: -81.1036148071288, lat: 29.4105567932129 },
  { lng: -81.1019439697266, lat: 29.4088897705079 },
  { lng: -81.1016082763671, lat: 29.4085636138917 },
  { lng: -81.1011428833007, lat: 29.4077968597413 },
  { lng: -81.1013870239258, lat: 29.4072246551514 },
  { lng: -81.1013870239258, lat: 29.4069423675538 },
  { lng: -81.1014251708984, lat: 29.4063682556152 },
  { lng: -81.1012954711914, lat: 29.4062232971192 },
  { lng: -81.1011123657226, lat: 29.4061126708984 },
  { lng: -81.099723815918, lat: 29.4061126708984 },
  { lng: -81.0991668701172, lat: 29.4055557250977 },
  { lng: -81.0991668701172, lat: 29.4050025939941 },
  { lng: -81.0988922119141, lat: 29.4047203063965 },
  { lng: -81.0988922119141, lat: 29.4044437408447 },
  { lng: -81.0991668701172, lat: 29.404167175293 },
  { lng: -81.0994415283203, lat: 29.4038887023926 },
  { lng: -81.0991668701172, lat: 29.4036102294922 },
  { lng: -81.0991668701172, lat: 29.4033336639404 },
  { lng: -81.0983352661133, lat: 29.4024982452393 },
  { lng: -81.0983352661133, lat: 29.4016666412355 },
  { lng: -81.0980529785156, lat: 29.4013881683351 },
  { lng: -81.0980529785156, lat: 29.4011116027833 },
  { lng: -81.0969467163085, lat: 29.3999996185303 },
  { lng: -81.0969467163085, lat: 29.3986110687256 },
  { lng: -81.0966644287108, lat: 29.3983325958252 },
  { lng: -81.0963897705078, lat: 29.3983325958252 },
  { lng: -81.095832824707, lat: 29.397777557373 },
  { lng: -81.095832824707, lat: 29.3974990844727 },
  { lng: -81.0952758789062, lat: 29.3969459533693 },
  { lng: -81.0952758789062, lat: 29.3966674804689 },
  { lng: -81.0950012207031, lat: 29.3963890075685 },
  { lng: -81.0950012207031, lat: 29.3961105346681 },
  { lng: -81.0947189331055, lat: 29.3958358764649 },
  { lng: -81.0947189331055, lat: 29.3952770233155 },
  { lng: -81.0944442749023, lat: 29.3950004577637 },
  { lng: -81.0944442749023, lat: 29.3947238922119 },
  { lng: -81.0941696166992, lat: 29.3944416046144 },
  { lng: -81.0941696166992, lat: 29.3938884735108 },
  { lng: -81.0938873291015, lat: 29.3936138153076 },
  { lng: -81.0938873291015, lat: 29.3930530548096 },
  { lng: -81.0936126708983, lat: 29.3927783966064 },
  { lng: -81.0936126708983, lat: 29.3925018310548 },
  { lng: -81.0933303833007, lat: 29.3922233581542 },
  { lng: -81.0933303833007, lat: 29.3916664123535 },
  { lng: -81.0930557250977, lat: 29.3913879394531 },
  { lng: -81.0930557250977, lat: 29.3911094665527 },
  { lng: -81.0922241210938, lat: 29.3902778625489 },
  { lng: -81.0922241210938, lat: 29.3899993896486 },
  { lng: -81.0919418334961, lat: 29.3897228240967 },
  { lng: -81.0919418334961, lat: 29.3894443511963 },
  { lng: -81.0911102294921, lat: 29.3886108398438 },
  { lng: -81.0911102294921, lat: 29.3869438171387 },
  { lng: -81.0913925170898, lat: 29.3866672515869 },
  { lng: -81.0913925170898, lat: 29.3863887786865 },
  { lng: -81.0911102294921, lat: 29.3861103057861 },
  { lng: -81.0911102294921, lat: 29.3855552673339 },
  { lng: -81.090835571289, lat: 29.3852787017823 },
  { lng: -81.090835571289, lat: 29.3850002288819 },
  { lng: -81.0894470214844, lat: 29.3836116790772 },
  { lng: -81.0894470214844, lat: 29.3833332061768 },
  { lng: -81.0891647338867, lat: 29.3830566406251 },
  { lng: -81.0891647338867, lat: 29.3827781677247 },
  { lng: -81.0888900756836, lat: 29.3824996948243 },
  { lng: -81.0888900756836, lat: 29.3822231292725 },
  { lng: -81.0886077880859, lat: 29.3819446563721 },
  { lng: -81.0886077880859, lat: 29.3799991607666 },
  { lng: -81.0880584716796, lat: 29.3794422149658 },
  { lng: -81.0880584716796, lat: 29.3791675567628 },
  { lng: -81.087776184082, lat: 29.3788890838624 },
  { lng: -81.087776184082, lat: 29.3783321380616 },
  { lng: -81.0875015258788, lat: 29.3780555725099 },
  { lng: -81.0875015258788, lat: 29.3777770996095 },
  { lng: -81.0872192382812, lat: 29.3775024414062 },
  { lng: -81.0872192382812, lat: 29.3769435882569 },
  { lng: -81.0869445800781, lat: 29.3766670227051 },
  { lng: -81.0869445800781, lat: 29.3755550384521 },
  { lng: -81.0868988037109, lat: 29.3752651214601 },
  { lng: -81.0876541137695, lat: 29.3753471374512 },
  { lng: -81.0883483886718, lat: 29.3756580352784 },
  { lng: -81.0889358520507, lat: 29.3761577606202 },
  { lng: -81.089485168457, lat: 29.3767070770264 },
  { lng: -81.0900497436523, lat: 29.3772411346436 },
  { lng: -81.0906829833984, lat: 29.3776702880859 },
  { lng: -81.0914001464843, lat: 29.3778820037842 },
  { lng: -81.0920944213867, lat: 29.3777561187745 },
  { lng: -81.0927581787108, lat: 29.3775024414062 },
  { lng: -81.093406677246, lat: 29.3771972656251 },
  { lng: -81.0940093994141, lat: 29.3768215179443 },
  { lng: -81.094482421875, lat: 29.3763256072999 },
  { lng: -81.0947875976562, lat: 29.3757247924804 },
  { lng: -81.0948715209961, lat: 29.3750743865968 },
  { lng: -81.0946960449219, lat: 29.3744602203369 },
  { lng: -81.0944137573242, lat: 29.3738842010499 },
  { lng: -81.0940856933593, lat: 29.3733234405518 },
  { lng: -81.0938110351562, lat: 29.3727283477783 },
  { lng: -81.0937423706055, lat: 29.3719882965088 },
  { lng: -81.093620300293, lat: 29.3712444305421 },
  { lng: -81.0933685302734, lat: 29.3705902099609 },
  { lng: -81.0930862426758, lat: 29.3699493408204 },
  { lng: -81.0928802490234, lat: 29.3692684173585 },
  { lng: -81.0928268432617, lat: 29.3687381744385 },
  { lng: -81.0928039550781, lat: 29.3685245513915 },
  { lng: -81.0927810668945, lat: 29.3677616119384 },
  { lng: -81.0927886962891, lat: 29.3669834136964 },
  { lng: -81.0928497314452, lat: 29.3661975860597 },
  { lng: -81.0930786132812, lat: 29.3654365539551 },
  { lng: -81.0935363769531, lat: 29.364845275879 },
  { lng: -81.0940246582031, lat: 29.3645324707031 },
  { lng: -81.0943374633789, lat: 29.3645668029785 },
  { lng: -81.0945434570312, lat: 29.3647594451905 },
  { lng: -81.0947189331055, lat: 29.3649311065674 },
  { lng: -81.0948944091797, lat: 29.365047454834 },
  { lng: -81.0950775146484, lat: 29.3651924133301 },
  { lng: -81.0952987670898, lat: 29.3653163909913 },
  { lng: -81.0955200195311, lat: 29.3654975891114 },
  { lng: -81.0958099365233, lat: 29.3656578063966 },
  { lng: -81.0960998535156, lat: 29.365852355957 },
  { lng: -81.0963973999023, lat: 29.3660984039307 },
  { lng: -81.0967178344727, lat: 29.3663215637207 },
  { lng: -81.097053527832, lat: 29.3665294647216 },
  { lng: -81.0973739624023, lat: 29.3667640686036 },
  { lng: -81.0977478027344, lat: 29.3668727874757 },
  { lng: -81.0981750488281, lat: 29.3667812347412 },
  { lng: -81.0985870361328, lat: 29.3664512634278 },
  { lng: -81.0989227294921, lat: 29.3659915924073 },
  { lng: -81.0990676879883, lat: 29.3653736114503 },
  { lng: -81.099021911621, lat: 29.3646755218506 },
  { lng: -81.0991439819335, lat: 29.3639698028564 },
  { lng: -81.0993118286132, lat: 29.3632926940918 },
  { lng: -81.0996627807617, lat: 29.362714767456 },
  { lng: -81.0999450683594, lat: 29.362138748169 },
  { lng: -81.1001358032227, lat: 29.3615436553956 },
  { lng: -81.100341796875, lat: 29.3609561920166 },
  { lng: -81.1005706787109, lat: 29.3604011535645 },
  { lng: -81.1008071899414, lat: 29.3598632812501 },
  { lng: -81.1009597778319, lat: 29.3592967987061 },
  { lng: -81.1008758544922, lat: 29.3586616516113 },
  { lng: -81.1006851196289, lat: 29.3580226898194 },
  { lng: -81.1005096435547, lat: 29.3573532104493 },
  { lng: -81.1004257202148, lat: 29.3566532135011 },
  { lng: -81.1002426147461, lat: 29.3560123443604 },
  { lng: -81.1000137329102, lat: 29.3554077148438 },
  { lng: -81.0998458862305, lat: 29.3548069000244 },
  { lng: -81.0999221801758, lat: 29.3542251586914 },
  { lng: -81.1000442504883, lat: 29.3536682128906 },
  { lng: -81.0999526977539, lat: 29.3531150817872 },
  { lng: -81.0996932983398, lat: 29.35262298584 },
  { lng: -81.0993347167968, lat: 29.352186203003 },
  { lng: -81.098876953125, lat: 29.3518352508544 },
  { lng: -81.0983963012695, lat: 29.3514938354493 },
  { lng: -81.0979232788086, lat: 29.3511066436768 },
  { lng: -81.0975036621093, lat: 29.3508338928222 },
  { lng: -81.0972213745117, lat: 29.3508338928222 },
  { lng: -81.0966644287108, lat: 29.3502769470215 },
  { lng: -81.0963897705078, lat: 29.3502769470215 },
  { lng: -81.0961074829102, lat: 29.3500022888184 },
  { lng: -81.095832824707, lat: 29.3497219085694 },
  { lng: -81.0955581665039, lat: 29.3494453430177 },
  { lng: -81.0950012207031, lat: 29.3488883972169 },
  { lng: -81.0941696166992, lat: 29.3488883972169 },
  { lng: -81.0938873291015, lat: 29.3486118316651 },
  { lng: -81.0936126708983, lat: 29.3488883972169 },
  { lng: -81.0933303833007, lat: 29.3488883972169 },
  { lng: -81.0924987792969, lat: 29.3497219085694 },
  { lng: -81.0922241210938, lat: 29.3497219085694 },
  { lng: -81.0919418334961, lat: 29.3494453430177 },
  { lng: -81.091667175293, lat: 29.3491668701173 },
  { lng: -81.0913925170898, lat: 29.3488883972169 },
  { lng: -81.0905532836913, lat: 29.3480548858643 },
  { lng: -81.0902786254882, lat: 29.3480548858643 },
  { lng: -81.0891647338867, lat: 29.34694480896 },
  { lng: -81.0894470214844, lat: 29.3466663360596 },
  { lng: -81.0894470214844, lat: 29.3461112976074 },
  { lng: -81.0897216796875, lat: 29.345832824707 },
  { lng: -81.0897216796875, lat: 29.3455562591553 },
  { lng: -81.0899963378906, lat: 29.3452777862549 },
  { lng: -81.0897216796875, lat: 29.3449993133545 },
  { lng: -81.0897216796875, lat: 29.3447227478028 },
  { lng: -81.0888900756836, lat: 29.3438892364503 },
  { lng: -81.0886077880859, lat: 29.3436107635499 },
  { lng: -81.0883331298828, lat: 29.3433341979981 },
  { lng: -81.0880584716796, lat: 29.3433341979981 },
  { lng: -81.087776184082, lat: 29.3430557250978 },
  { lng: -81.0875015258788, lat: 29.3430557250978 },
  { lng: -81.0869445800781, lat: 29.3436107635499 },
  { lng: -81.086669921875, lat: 29.3438892364503 },
  { lng: -81.0869445800781, lat: 29.3441677093507 },
  { lng: -81.0869445800781, lat: 29.3447227478028 },
  { lng: -81.0872192382812, lat: 29.3449993133545 },
  { lng: -81.0872192382812, lat: 29.345832824707 },
  { lng: -81.0875015258788, lat: 29.3461112976074 },
  { lng: -81.0875015258788, lat: 29.3466663360596 },
  { lng: -81.087776184082, lat: 29.34694480896 },
  { lng: -81.087776184082, lat: 29.3472213745118 },
  { lng: -81.0880584716796, lat: 29.3474998474122 },
  { lng: -81.0880584716796, lat: 29.3477783203126 },
  { lng: -81.0883331298828, lat: 29.3480548858643 },
  { lng: -81.0888900756836, lat: 29.3486118316651 },
  { lng: -81.0891647338867, lat: 29.3488883972169 },
  { lng: -81.0891647338867, lat: 29.3494453430177 },
  { lng: -81.0888900756836, lat: 29.3497219085694 },
  { lng: -81.0888900756836, lat: 29.3502769470215 },
  { lng: -81.0891647338867, lat: 29.3505554199218 },
  { lng: -81.0891647338867, lat: 29.351110458374 },
  { lng: -81.0894470214844, lat: 29.3513908386232 },
  { lng: -81.0894470214844, lat: 29.3516674041748 },
  { lng: -81.0898284912109, lat: 29.3520507812501 },
  { lng: -81.0905532836913, lat: 29.3527774810792 },
  { lng: -81.090835571289, lat: 29.3527774810792 },
  { lng: -81.0911102294921, lat: 29.3530559539795 },
  { lng: -81.0913925170898, lat: 29.3527774810792 },
  { lng: -81.0913085937499, lat: 29.3534011840821 },
  { lng: -81.0911560058594, lat: 29.3541831970215 },
  { lng: -81.0909729003906, lat: 29.3549537658692 },
  { lng: -81.0906219482421, lat: 29.3556327819824 },
  { lng: -81.0901260375977, lat: 29.3561363220216 },
  { lng: -81.0895690917969, lat: 29.3563270568849 },
  { lng: -81.088981628418, lat: 29.3563766479493 },
  { lng: -81.0883941650391, lat: 29.3563594818116 },
  { lng: -81.0878067016602, lat: 29.3562774658203 },
  { lng: -81.0872650146484, lat: 29.3560638427736 },
  { lng: -81.0867767333984, lat: 29.3557186126709 },
  { lng: -81.0863265991211, lat: 29.3552970886231 },
  { lng: -81.0858993530273, lat: 29.3548488616944 },
  { lng: -81.085594177246, lat: 29.3544387817383 },
  { lng: -81.0855255126953, lat: 29.3543453216553 },
  { lng: -81.0852279663086, lat: 29.3537769317628 },
  { lng: -81.0849151611328, lat: 29.3532123565675 },
  { lng: -81.0845413208007, lat: 29.3526668548584 },
  { lng: -81.0842056274414, lat: 29.3520812988281 },
  { lng: -81.0839080810547, lat: 29.351469039917 },
  { lng: -81.0836334228516, lat: 29.3508377075196 },
  { lng: -81.083366394043, lat: 29.3502006530762 },
  { lng: -81.0831069946288, lat: 29.349552154541 },
  { lng: -81.082878112793, lat: 29.3488807678224 },
  { lng: -81.0826644897461, lat: 29.348201751709 },
  { lng: -81.0824584960936, lat: 29.3475151062012 },
  { lng: -81.0822143554688, lat: 29.3468456268312 },
  { lng: -81.0819625854492, lat: 29.3461837768555 },
  { lng: -81.0817489624023, lat: 29.3455085754395 },
  { lng: -81.0816192626953, lat: 29.3448143005372 },
  { lng: -81.0814666748046, lat: 29.344129562378 },
  { lng: -81.0813217163086, lat: 29.3434467315674 },
  { lng: -81.0811462402344, lat: 29.3427734375 },
  { lng: -81.0809707641602, lat: 29.3421230316162 },
  { lng: -81.080680847168, lat: 29.341594696045 },
  { lng: -81.0802993774414, lat: 29.341163635254 },
  { lng: -81.0799255371094, lat: 29.3407211303712 },
  { lng: -81.0796432495117, lat: 29.3402042388917 },
  { lng: -81.0794296264648, lat: 29.3396301269532 },
  { lng: -81.0792388916016, lat: 29.339038848877 },
  { lng: -81.0791320800781, lat: 29.3384017944336 },
  { lng: -81.079231262207, lat: 29.3376941680908 },
  { lng: -81.0793075561522, lat: 29.3369884490967 },
  { lng: -81.0792236328125, lat: 29.3363552093507 },
  { lng: -81.0789642333984, lat: 29.3359146118164 },
  { lng: -81.0786666870117, lat: 29.3355350494384 },
  { lng: -81.0783615112305, lat: 29.3351612091064 },
  { lng: -81.0780639648437, lat: 29.3347759246827 },
  { lng: -81.0777587890624, lat: 29.3343715667725 },
  { lng: -81.0774307250977, lat: 29.3339748382568 },
  { lng: -81.0770797729492, lat: 29.3335914611817 },
  { lng: -81.0767517089844, lat: 29.3331813812256 },
  { lng: -81.0765228271484, lat: 29.3327026367188 },
  { lng: -81.0765075683593, lat: 29.3321533203126 },
  { lng: -81.0765609741211, lat: 29.3315906524658 },
  { lng: -81.0765151977538, lat: 29.3310413360596 },
  { lng: -81.0763549804686, lat: 29.3305244445801 },
  { lng: -81.0761489868163, lat: 29.3300228118897 },
  { lng: -81.075942993164, lat: 29.3295097351074 },
  { lng: -81.0757751464843, lat: 29.3289718627931 },
  { lng: -81.0755462646484, lat: 29.3284816741944 },
  { lng: -81.0752868652343, lat: 29.3280143737793 },
  { lng: -81.0750198364257, lat: 29.3275623321533 },
  { lng: -81.0747146606445, lat: 29.3271598815919 },
  { lng: -81.0744400024413, lat: 29.3267040252686 },
  { lng: -81.0741653442383, lat: 29.3262424468994 },
  { lng: -81.0738906860352, lat: 29.3257732391357 },
  { lng: -81.0736312866211, lat: 29.3253059387207 },
  { lng: -81.0733947753905, lat: 29.3248233795167 },
  { lng: -81.0731124877929, lat: 29.3243751525879 },
  { lng: -81.0728378295898, lat: 29.3239154815674 },
  { lng: -81.0726089477538, lat: 29.3234195709229 },
  { lng: -81.072395324707, lat: 29.3228950500489 },
  { lng: -81.0721359252929, lat: 29.3223781585694 },
  { lng: -81.071907043457, lat: 29.3218441009523 },
  { lng: -81.071662902832, lat: 29.3213329315186 },
  { lng: -81.0714111328125, lat: 29.3208236694336 },
  { lng: -81.0711975097656, lat: 29.3203105926515 },
  { lng: -81.0709991455078, lat: 29.3198013305665 },
  { lng: -81.0707550048828, lat: 29.3193321228028 },
  { lng: -81.0704574584961, lat: 29.3189353942872 },
  { lng: -81.0701293945312, lat: 29.3185653686523 },
  { lng: -81.0698165893555, lat: 29.3181724548341 },
  { lng: -81.0695114135742, lat: 29.3177814483644 },
  { lng: -81.0693283081055, lat: 29.3173007965088 },
  { lng: -81.0693054199219, lat: 29.3167304992676 },
  { lng: -81.0691375732422, lat: 29.3161811828613 },
  { lng: -81.0688858032226, lat: 29.3156547546387 },
  { lng: -81.0686264038085, lat: 29.3151264190673 },
  { lng: -81.0683822631836, lat: 29.3145809173585 },
  { lng: -81.0681686401367, lat: 29.3140087127686 },
  { lng: -81.0678939819336, lat: 29.3134899139405 },
  { lng: -81.0675735473632, lat: 29.3130340576172 },
  { lng: -81.0672607421874, lat: 29.312578201294 },
  { lng: -81.0670013427734, lat: 29.3120899200439 },
  { lng: -81.066795349121, lat: 29.3115501403808 },
  { lng: -81.0665512084961, lat: 29.3110275268556 },
  { lng: -81.0662612915039, lat: 29.3105297088624 },
  { lng: -81.0658340454101, lat: 29.3099994659424 },
  { lng: -81.0658340454101, lat: 29.3097229003907 },
  { lng: -81.0655517578124, lat: 29.3094444274902 },
  { lng: -81.0655517578124, lat: 29.3091659545898 },
  { lng: -81.0652770996093, lat: 29.3088893890382 },
  { lng: -81.0652770996093, lat: 29.3086109161378 },
  { lng: -81.0650024414061, lat: 29.3083324432374 },
  { lng: -81.0647201538085, lat: 29.3080558776857 },
  { lng: -81.0644454956055, lat: 29.3077774047853 },
  { lng: -81.0644454956055, lat: 29.3075008392335 },
  { lng: -81.0641632080078, lat: 29.3072223663331 },
  { lng: -81.0638885498047, lat: 29.3069438934327 },
  { lng: -81.0638885498047, lat: 29.3063888549805 },
  { lng: -81.0636138916016, lat: 29.3061103820801 },
  { lng: -81.0636138916016, lat: 29.3058338165283 },
  { lng: -81.0633316040039, lat: 29.3055553436279 },
  { lng: -81.0633316040039, lat: 29.3050003051758 },
  { lng: -81.0630569458008, lat: 29.3047218322754 },
  { lng: -81.0630569458008, lat: 29.3041667938232 },
  { lng: -81.0627746582031, lat: 29.3038883209229 },
  { lng: -81.0627746582031, lat: 29.3036117553712 },
  { lng: -81.0622253417968, lat: 29.3030567169191 },
  { lng: -81.0622253417968, lat: 29.3027782440187 },
  { lng: -81.0619430541992, lat: 29.3024997711183 },
  { lng: -81.061668395996, lat: 29.3022212982179 },
  { lng: -81.061668395996, lat: 29.3016662597657 },
  { lng: -81.0613861083984, lat: 29.3013896942139 },
  { lng: -81.0613861083984, lat: 29.3008346557617 },
  { lng: -81.0611114501953, lat: 29.3005561828614 },
  { lng: -81.0611114501953, lat: 29.300277709961 },
  { lng: -81.0602798461914, lat: 29.2994422912598 },
  { lng: -81.0599975585938, lat: 29.299165725708 },
  { lng: -81.0599975585938, lat: 29.2988891601562 },
  { lng: -81.0597229003906, lat: 29.2986125946046 },
  { lng: -81.0597229003906, lat: 29.2975025177003 },
  { lng: -81.0591659545898, lat: 29.2969436645509 },
  { lng: -81.0591659545898, lat: 29.2966670989991 },
  { lng: -81.0588912963867, lat: 29.2963886260987 },
  { lng: -81.0588912963867, lat: 29.2949981689454 },
  { lng: -81.058609008789, lat: 29.2947216033936 },
  { lng: -81.0583343505859, lat: 29.2944450378418 },
  { lng: -81.0583343505859, lat: 29.2930564880371 },
  { lng: -81.0580520629883, lat: 29.2927761077881 },
  { lng: -81.0580520629883, lat: 29.2924995422363 },
  { lng: -81.0577774047852, lat: 29.2922229766847 },
  { lng: -81.0577774047852, lat: 29.2919445037841 },
  { lng: -81.057502746582, lat: 29.2916679382325 },
  { lng: -81.0572204589844, lat: 29.2913894653321 },
  { lng: -81.0572204589844, lat: 29.2911109924318 },
  { lng: -81.0569458007812, lat: 29.2908325195314 },
  { lng: -81.0569458007812, lat: 29.290554046631 },
  { lng: -81.0566635131836, lat: 29.2902774810792 },
  { lng: -81.0569458007812, lat: 29.2900009155274 },
  { lng: -81.0569458007812, lat: 29.2891674041749 },
  { lng: -81.0566482543945, lat: 29.2886295318604 },
  { lng: -81.0563735961914, lat: 29.2880630493165 },
  { lng: -81.0560531616211, lat: 29.2875308990479 },
  { lng: -81.0556411743163, lat: 29.2870616912842 },
  { lng: -81.0551681518554, lat: 29.2866077423096 },
  { lng: -81.0547256469727, lat: 29.2861309051514 },
  { lng: -81.0543594360352, lat: 29.285629272461 },
  { lng: -81.0542449951172, lat: 29.285083770752 },
  { lng: -81.0543136596679, lat: 29.2845478057862 },
  { lng: -81.054229736328, lat: 29.2840023040771 },
  { lng: -81.0540466308594, lat: 29.2834644317626 },
  { lng: -81.0538253784179, lat: 29.2829284667969 },
  { lng: -81.0535125732422, lat: 29.2824668884278 },
  { lng: -81.0533370971679, lat: 29.2822227478027 },
  { lng: -81.0530548095703, lat: 29.2819442749023 },
  { lng: -81.0530548095703, lat: 29.281665802002 },
  { lng: -81.0524978637695, lat: 29.2811107635498 },
  { lng: -81.0524978637695, lat: 29.280834197998 },
  { lng: -81.0525512695312, lat: 29.2806758880616 },
  { lng: -81.0527877807617, lat: 29.2799854278566 },
  { lng: -81.0526657104492, lat: 29.2793979644777 },
  { lng: -81.0523529052734, lat: 29.2789115905763 },
  { lng: -81.0519943237305, lat: 29.2784595489503 },
  { lng: -81.051643371582, lat: 29.2780094146729 },
  { lng: -81.0512695312499, lat: 29.2776126861572 },
  { lng: -81.0508651733398, lat: 29.2772674560548 },
  { lng: -81.0505142211914, lat: 29.2768898010254 },
  { lng: -81.0502471923828, lat: 29.2764301300049 },
  { lng: -81.0501403808594, lat: 29.2758483886719 },
  { lng: -81.0499420166016, lat: 29.2752933502197 },
  { lng: -81.0496597290039, lat: 29.2748050689698 },
  { lng: -81.0493392944335, lat: 29.2743492126464 },
  { lng: -81.0489959716797, lat: 29.2739067077637 },
  { lng: -81.0486526489258, lat: 29.273473739624 },
  { lng: -81.0483245849609, lat: 29.2730140686035 },
  { lng: -81.0480422973633, lat: 29.2725143432618 },
  { lng: -81.0477752685547, lat: 29.2720127105712 },
  { lng: -81.0475158691406, lat: 29.2715034484863 },
  { lng: -81.0473022460938, lat: 29.2709789276123 },
  { lng: -81.0471954345703, lat: 29.2704315185548 },
  { lng: -81.0470657348632, lat: 29.2699031829835 },
  { lng: -81.0468826293944, lat: 29.2694168090821 },
  { lng: -81.0467376708984, lat: 29.2689266204834 },
  { lng: -81.0466613769531, lat: 29.2684211730958 },
  { lng: -81.0465774536132, lat: 29.2679290771486 },
  { lng: -81.046401977539, lat: 29.2674827575683 },
  { lng: -81.0462036132812, lat: 29.2670478820802 },
  { lng: -81.0459899902343, lat: 29.2666244506835 },
  { lng: -81.0457916259766, lat: 29.2661991119385 },
  { lng: -81.0455474853514, lat: 29.265817642212 },
  { lng: -81.0452575683593, lat: 29.2654743194581 },
  { lng: -81.0449600219726, lat: 29.2651672363281 },
  { lng: -81.0446472167969, lat: 29.2648773193361 },
  { lng: -81.0444412231445, lat: 29.2647228240967 },
  { lng: -81.0441665649414, lat: 29.2647228240967 },
  { lng: -81.0438919067382, lat: 29.2644443511963 },
  { lng: -81.0436096191406, lat: 29.2641677856445 },
  { lng: -81.0436096191406, lat: 29.2638893127441 },
  { lng: -81.0438919067382, lat: 29.2636108398438 },
  { lng: -81.0441665649414, lat: 29.2633342742919 },
  { lng: -81.0439987182617, lat: 29.2631492614746 },
  { lng: -81.0436477661133, lat: 29.262550354004 },
  { lng: -81.0433120727539, lat: 29.2619552612305 },
  { lng: -81.0429534912109, lat: 29.2613811492921 },
  { lng: -81.0425109863281, lat: 29.2608623504638 },
  { lng: -81.0420455932617, lat: 29.2603778839111 },
  { lng: -81.0416107177734, lat: 29.259864807129 },
  { lng: -81.0413131713867, lat: 29.2592277526857 },
  { lng: -81.0411911010742, lat: 29.2584800720216 },
  { lng: -81.0409317016602, lat: 29.2577743530274 },
  { lng: -81.0405502319336, lat: 29.2572364807129 },
  { lng: -81.040168762207, lat: 29.2567443847656 },
  { lng: -81.0399398803711, lat: 29.2562255859376 },
  { lng: -81.0397109985351, lat: 29.2556953430176 },
  { lng: -81.0394821166992, lat: 29.255163192749 },
  { lng: -81.0392684936523, lat: 29.2546405792237 },
  { lng: -81.0389938354492, lat: 29.2542495727539 },
  { lng: -81.0387344360352, lat: 29.2538681030273 },
  { lng: -81.0384979248047, lat: 29.253475189209 },
  { lng: -81.0382843017578, lat: 29.2530746459962 },
  { lng: -81.0380325317383, lat: 29.252700805664 },
  { lng: -81.0378570556641, lat: 29.252281188965 },
  { lng: -81.0377655029297, lat: 29.251802444458 },
  { lng: -81.0376663208008, lat: 29.2513294219971 },
  { lng: -81.0375289916992, lat: 29.2508754730225 },
  { lng: -81.0373611450195, lat: 29.250436782837 },
  { lng: -81.0370101928711, lat: 29.2500610351562 },
  { lng: -81.0364456176758, lat: 29.2497825622559 },
  { lng: -81.0359878540038, lat: 29.2492294311525 },
  { lng: -81.0357818603516, lat: 29.2484207153321 },
  { lng: -81.0358963012695, lat: 29.2476806640626 },
  { lng: -81.0360260009766, lat: 29.2469291687012 },
  { lng: -81.0360565185547, lat: 29.2461853027345 },
  { lng: -81.0359878540038, lat: 29.2454872131348 },
  { lng: -81.0358200073241, lat: 29.2448825836183 },
  { lng: -81.0356292724608, lat: 29.2443103790284 },
  { lng: -81.0355072021484, lat: 29.2437210083009 },
  { lng: -81.0353393554687, lat: 29.2431430816651 },
  { lng: -81.0351104736328, lat: 29.2425937652589 },
  { lng: -81.0348892211914, lat: 29.2420558929444 },
  { lng: -81.0346145629883, lat: 29.2415828704835 },
  { lng: -81.0342712402343, lat: 29.2411670684814 },
  { lng: -81.0338668823242, lat: 29.2408123016359 },
  { lng: -81.033447265625, lat: 29.2404785156251 },
  { lng: -81.033088684082, lat: 29.2401180267334 },
  { lng: -81.0327911376953, lat: 29.2397403717042 },
  { lng: -81.0324707031249, lat: 29.2394027709961 },
  { lng: -81.032112121582, lat: 29.2391319274902 },
  { lng: -81.0316696166992, lat: 29.2374992370607 },
  { lng: -81.0319442749023, lat: 29.2372226715088 },
  { lng: -81.0316696166992, lat: 29.2369441986085 },
  { lng: -81.0313873291016, lat: 29.2369441986085 },
  { lng: -81.0311126708984, lat: 29.2366657257081 },
  { lng: -81.0305557250976, lat: 29.2366657257081 },
  { lng: -81.0303115844727, lat: 29.2367687225343 },
  { lng: -81.0298233032227, lat: 29.2362136840821 },
  { lng: -81.0295028686523, lat: 29.2358016967774 },
  { lng: -81.0293884277344, lat: 29.2356491088868 },
  { lng: -81.0289230346679, lat: 29.2351264953613 },
  { lng: -81.0284042358398, lat: 29.234691619873 },
  { lng: -81.0279541015625, lat: 29.2341613769532 },
  { lng: -81.0275421142578, lat: 29.233585357666 },
  { lng: -81.0271911621094, lat: 29.2329463958741 },
  { lng: -81.0267028808594, lat: 29.2315616607667 },
  { lng: -81.0265808105469, lat: 29.230821609497 },
  { lng: -81.02628326416, lat: 29.2301216125488 },
  { lng: -81.0257339477539, lat: 29.2296562194825 },
  { lng: -81.0250549316406, lat: 29.2295665740968 },
  { lng: -81.0227813720703, lat: 29.2277774810791 },
  { lng: -81.0227813720703, lat: 29.2272224426271 },
  { lng: -81.0224990844727, lat: 29.2269439697267 },
  { lng: -81.0219421386719, lat: 29.2269439697267 },
  { lng: -81.0216674804688, lat: 29.2272224426271 },
  { lng: -81.021385192871, lat: 29.2272224426271 },
  { lng: -81.02099609375, lat: 29.2268753051758 },
  { lng: -81.0206146240234, lat: 29.2264461517335 },
  { lng: -81.0201950073242, lat: 29.2259540557862 },
  { lng: -81.0202255249023, lat: 29.2255706787111 },
  { lng: -81.0203857421875, lat: 29.2252101898193 },
  { lng: -81.0205154418945, lat: 29.2249279022217 },
  { lng: -81.0205535888671, lat: 29.2246761322021 },
  { lng: -81.0205535888671, lat: 29.224443435669 },
  { lng: -81.0205535888671, lat: 29.2242069244385 },
  { lng: -81.0205459594726, lat: 29.2239646911622 },
  { lng: -81.0205383300781, lat: 29.2237129211426 },
  { lng: -81.0206146240234, lat: 29.2234077453614 },
  { lng: -81.0211105346679, lat: 29.2229080200196 },
  { lng: -81.0215301513672, lat: 29.2222766876221 },
  { lng: -81.0218048095702, lat: 29.2216510772706 },
  { lng: -81.021842956543, lat: 29.2215690612793 },
  { lng: -81.0221557617187, lat: 29.2209148406983 },
  { lng: -81.022361755371, lat: 29.2202377319337 },
  { lng: -81.0223693847656, lat: 29.2195320129395 },
  { lng: -81.0222015380859, lat: 29.2188243865967 },
  { lng: -81.0219573974609, lat: 29.2181434631348 },
  { lng: -81.0216140747069, lat: 29.2175369262696 },
  { lng: -81.0212326049804, lat: 29.2169551849366 },
  { lng: -81.0206985473633, lat: 29.2164001464844 },
  { lng: -81.0204772949219, lat: 29.2156429290773 },
  { lng: -81.0201416015625, lat: 29.2149810791016 },
  { lng: -81.0196533203125, lat: 29.214454650879 },
  { lng: -81.0194778442382, lat: 29.2143783569336 },
  { lng: -81.0189743041992, lat: 29.2141609191895 },
  { lng: -81.0183334350585, lat: 29.2141666412355 },
  { lng: -81.0177764892578, lat: 29.2141666412355 },
  { lng: -81.01611328125, lat: 29.2133331298829 },
  { lng: -81.01611328125, lat: 29.2130546569825 },
  { lng: -81.0159530639648, lat: 29.212917327881 },
  { lng: -81.015754699707, lat: 29.212688446045 },
  { lng: -81.0154571533203, lat: 29.2125053405763 },
  { lng: -81.0151138305663, lat: 29.2123184204102 },
  { lng: -81.0147705078125, lat: 29.2120742797851 },
  { lng: -81.0144271850585, lat: 29.2117671966553 },
  { lng: -81.01407623291, lat: 29.2114372253418 },
  { lng: -81.0137023925781, lat: 29.2111034393311 },
  { lng: -81.0133361816406, lat: 29.2107257843018 },
  { lng: -81.0130233764648, lat: 29.2102184295655 },
  { lng: -81.0127944946289, lat: 29.2096652984619 },
  { lng: -81.0124969482422, lat: 29.2091674804689 },
  { lng: -81.0122222900391, lat: 29.2088890075685 },
  { lng: -81.0119476318359, lat: 29.2086105346681 },
  { lng: -81.0119476318359, lat: 29.2080554962159 },
  { lng: -81.0116653442382, lat: 29.2077770233155 },
  { lng: -81.0116653442382, lat: 29.2069416046144 },
  { lng: -81.0113906860351, lat: 29.2066669464112 },
  { lng: -81.0113906860351, lat: 29.2063884735108 },
  { lng: -81.0111083984374, lat: 29.2061138153076 },
  { lng: -81.0111083984374, lat: 29.2050018310548 },
  { lng: -81.010856628418, lat: 29.2047500610352 },
  { lng: -81.0113677978516, lat: 29.2041931152344 },
  { lng: -81.0118103027344, lat: 29.2037067413331 },
  { lng: -81.0122528076171, lat: 29.2033157348633 },
  { lng: -81.0121841430664, lat: 29.2029266357421 },
  { lng: -81.0118103027344, lat: 29.2029266357421 },
  { lng: -81.0110702514648, lat: 29.2029895782471 },
  { lng: -81.0104598999023, lat: 29.2032718658448 },
  { lng: -81.0097198486328, lat: 29.2036094665527 },
  { lng: -81.0086135864257, lat: 29.2036094665527 },
  { lng: -81.0083312988281, lat: 29.2033329010011 },
  { lng: -81.0094375610352, lat: 29.2022285461427 },
  { lng: -81.0100021362305, lat: 29.201665878296 },
  { lng: -81.0102767944336, lat: 29.201665878296 },
  { lng: -81.0102767944336, lat: 29.2013874053956 },
  { lng: -81.0100021362305, lat: 29.2011108398438 },
  { lng: -81.0097198486328, lat: 29.2011108398438 },
  { lng: -81.0094451904297, lat: 29.200834274292 },
  { lng: -81.009162902832, lat: 29.200834274292 },
  { lng: -81.0088882446289, lat: 29.2005558013916 },
  { lng: -81.0086135864257, lat: 29.2005558013916 },
  { lng: -81.0083312988281, lat: 29.2002773284913 },
  { lng: -81.0080566406249, lat: 29.2005558013916 },
  { lng: -81.0077743530273, lat: 29.2002773284913 },
  { lng: -81.0076599121093, lat: 29.2001628875734 },
  { lng: -81.0074996948241, lat: 29.2000007629395 },
  { lng: -81.0075225830078, lat: 29.1988887786865 },
  { lng: -81.0069427490234, lat: 29.1983337402345 },
  { lng: -81.0069427490234, lat: 29.1977787017823 },
  { lng: -81.0066680908203, lat: 29.1975002288819 },
  { lng: -81.0066680908203, lat: 29.1969451904297 },
  { lng: -81.0063858032227, lat: 29.1966667175294 },
  { lng: -81.0063858032227, lat: 29.1955547332764 },
  { lng: -81.0055541992187, lat: 29.1947231292725 },
  { lng: -81.0055541992187, lat: 29.1944446563721 },
  { lng: -81.0060501098632, lat: 29.1938858032227 },
  { lng: -81.0061111450195, lat: 29.192777633667 },
  { lng: -81.0058364868164, lat: 29.1924991607666 },
  { lng: -81.0057067871094, lat: 29.1923732757569 },
  { lng: -81.0055541992187, lat: 29.192222595215 },
  { lng: -81.0055847167968, lat: 29.1919250488281 },
  { lng: -81.0052795410156, lat: 29.1916675567628 },
  { lng: -81.0052795410156, lat: 29.1905555725098 },
  { lng: -81.0047225952148, lat: 29.1900024414062 },
  { lng: -81.0044479370116, lat: 29.1897201538087 },
  { lng: -81.0044479370116, lat: 29.1883888244629 },
  { lng: -81.0038909912109, lat: 29.1877784729004 },
  { lng: -81.0036087036133, lat: 29.1874980926514 },
  { lng: -81.0036087036133, lat: 29.1872215270996 },
  { lng: -81.0037612915038, lat: 29.1870727539064 },
  { lng: -81.0038909912109, lat: 29.1869449615478 },
  { lng: -81.0038909912109, lat: 29.1866664886474 },
  { lng: -81.0036087036133, lat: 29.1863861083984 },
  { lng: -81.0032730102538, lat: 29.1861438751222 },
  { lng: -81.0033340454102, lat: 29.1855564117433 },
  { lng: -81.0030517578125, lat: 29.1852779388429 },
  { lng: -81.0027770996094, lat: 29.1849994659425 },
  { lng: -81.0027770996094, lat: 29.1830558776855 },
  { lng: -81.0030517578125, lat: 29.1827774047852 },
  { lng: -81.0030517578125, lat: 29.1816673278809 },
  { lng: -81.0027770996094, lat: 29.1813888549805 },
  { lng: -81.0025024414062, lat: 29.1811103820801 },
  { lng: -81.0025024414062, lat: 29.1800003051759 },
  { lng: -81.0022201538085, lat: 29.1797237396241 },
  { lng: -81.0022201538085, lat: 29.1794452667237 },
  { lng: -81.0019454956054, lat: 29.1791667938234 },
  { lng: -81.0019454956054, lat: 29.1783313751222 },
  { lng: -81.0016632080078, lat: 29.1780548095704 },
  { lng: -81.0016632080078, lat: 29.1775016784668 },
  { lng: -81.0011138916016, lat: 29.176944732666 },
  { lng: -81.0008316040039, lat: 29.1766662597656 },
  { lng: -81.0008316040039, lat: 29.1758327484131 },
  { lng: -81.0005569458008, lat: 29.1755561828613 },
  { lng: -81.0, lat: 29.1752510070801 },
  { lng: -80.999168395996, lat: 29.173610687256 },
  { lng: -80.999168395996, lat: 29.1733341217042 },
  { lng: -80.9991073608397, lat: 29.1729164123535 },
  { lng: -80.9990692138671, lat: 29.1724605560303 },
  { lng: -80.9988861083984, lat: 29.1720905303955 },
  { lng: -80.998664855957, lat: 29.171730041504 },
  { lng: -80.998420715332, lat: 29.171365737915 },
  { lng: -80.9982299804687, lat: 29.1709480285646 },
  { lng: -80.9981689453125, lat: 29.1704273223878 },
  { lng: -80.9979705810547, lat: 29.1699619293214 },
  { lng: -80.9976654052734, lat: 29.1695251464845 },
  { lng: -80.9974975585938, lat: 29.1689987182617 },
  { lng: -80.9973831176757, lat: 29.1684474945069 },
  { lng: -80.9973678588867, lat: 29.1678829193116 },
  { lng: -80.9972915649413, lat: 29.1673641204835 },
  { lng: -80.9970626831055, lat: 29.1669311523438 },
  { lng: -80.9967880249023, lat: 29.1665172576905 },
  { lng: -80.9964828491211, lat: 29.1661128997803 },
  { lng: -80.9961929321289, lat: 29.1656837463379 },
  { lng: -80.9960021972656, lat: 29.1651706695558 },
  { lng: -80.9958038330078, lat: 29.1646575927735 },
  { lng: -80.9955520629882, lat: 29.164161682129 },
  { lng: -80.9952926635741, lat: 29.1636734008789 },
  { lng: -80.9950942993163, lat: 29.1631469726563 },
  { lng: -80.9951095581055, lat: 29.1625556945802 },
  { lng: -80.9949645996094, lat: 29.1619663238525 },
  { lng: -80.9947052001953, lat: 29.161449432373 },
  { lng: -80.9943313598632, lat: 29.1610851287843 },
  { lng: -80.9939651489258, lat: 29.1607112884523 },
  { lng: -80.9937133789062, lat: 29.160270690918 },
  { lng: -80.9937286376953, lat: 29.1597957611084 },
  { lng: -80.993553161621, lat: 29.1594028472901 },
  { lng: -80.993293762207, lat: 29.1590614318848 },
  { lng: -80.9929504394531, lat: 29.1587753295899 },
  { lng: -80.9926223754882, lat: 29.1584568023682 },
  { lng: -80.9923019409179, lat: 29.1581134796144 },
  { lng: -80.992057800293, lat: 29.1576805114746 },
  { lng: -80.9919204711913, lat: 29.1571464538575 },
  { lng: -80.9917602539061, lat: 29.1566028594972 },
  { lng: -80.9916229248047, lat: 29.15602684021 },
  { lng: -80.9914627075195, lat: 29.1554679870607 },
  { lng: -80.9914779663085, lat: 29.1549224853516 },
  { lng: -80.9911880493163, lat: 29.1544837951661 },
  { lng: -80.9909133911133, lat: 29.1539878845216 },
  { lng: -80.9905776977539, lat: 29.1535091400146 },
  { lng: -80.9902801513672, lat: 29.1530094146729 },
  { lng: -80.9899978637695, lat: 29.1524925231934 },
  { lng: -80.9897232055663, lat: 29.1519660949707 },
  { lng: -80.9894714355468, lat: 29.1514358520508 },
  { lng: -80.9892349243164, lat: 29.1509094238281 },
  { lng: -80.9889144897461, lat: 29.1504917144777 },
  { lng: -80.9885787963867, lat: 29.150079727173 },
  { lng: -80.9882202148437, lat: 29.1496753692628 },
  { lng: -80.9878997802734, lat: 29.1492176055908 },
  { lng: -80.9875335693359, lat: 29.1487903594972 },
  { lng: -80.9871520996093, lat: 29.1483764648437 },
  { lng: -80.986701965332, lat: 29.1480388641358 },
  { lng: -80.9863281249999, lat: 29.1476669311523 },
  { lng: -80.9862060546874, lat: 29.1472148895264 },
  { lng: -80.9863891601562, lat: 29.1467704772949 },
  { lng: -80.9866638183594, lat: 29.1463871002197 },
  { lng: -80.9866638183594, lat: 29.1458339691163 },
  { lng: -80.9861145019531, lat: 29.1452789306642 },
  { lng: -80.9861145019531, lat: 29.1450004577638 },
  { lng: -80.9855575561523, lat: 29.144443511963 },
  { lng: -80.9855575561523, lat: 29.1433334350587 },
  { lng: -80.9852752685546, lat: 29.1430568695069 },
  { lng: -80.9852752685546, lat: 29.1427783966065 },
  { lng: -80.9847259521484, lat: 29.1422214508057 },
  { lng: -80.9847259521484, lat: 29.1419448852539 },
  { lng: -80.9844436645508, lat: 29.1416664123535 },
  { lng: -80.9844436645508, lat: 29.1413879394532 },
  { lng: -80.9841690063477, lat: 29.1411113739014 },
  { lng: -80.9841690063477, lat: 29.1408348083496 },
  { lng: -80.9836120605469, lat: 29.1402778625488 },
  { lng: -80.9836120605469, lat: 29.1397247314454 },
  { lng: -80.983055114746, lat: 29.1391658782958 },
  { lng: -80.983055114746, lat: 29.1388893127443 },
  { lng: -80.9824981689453, lat: 29.138334274292 },
  { lng: -80.9824981689453, lat: 29.1377773284913 },
  { lng: -80.9822235107421, lat: 29.1375026702881 },
  { lng: -80.9822235107421, lat: 29.1372203826905 },
  { lng: -80.9819412231444, lat: 29.1369438171387 },
  { lng: -80.9819412231444, lat: 29.1363906860352 },
  { lng: -80.9816665649414, lat: 29.1361083984376 },
  { lng: -80.9816665649414, lat: 29.1358337402344 },
  { lng: -80.9811096191406, lat: 29.135280609131 },
  { lng: -80.9811096191406, lat: 29.1349983215333 },
  { lng: -80.9808349609375, lat: 29.1347217559814 },
  { lng: -80.9808349609375, lat: 29.1341667175292 },
  { lng: -80.9805526733398, lat: 29.1338882446289 },
  { lng: -80.9805526733398, lat: 29.1333332061769 },
  { lng: -80.9797210693359, lat: 29.1324996948243 },
  { lng: -80.9797210693359, lat: 29.1322231292725 },
  { lng: -80.9791641235351, lat: 29.1316661834717 },
  { lng: -80.9791641235351, lat: 29.13138961792 },
  { lng: -80.9788894653319, lat: 29.1311111450196 },
  { lng: -80.9788894653319, lat: 29.1305561065674 },
  { lng: -80.9780578613281, lat: 29.1297225952148 },
  { lng: -80.9780578613281, lat: 29.1286106109619 },
  { lng: -80.9769439697265, lat: 29.1275005340577 },
  { lng: -80.9769439697265, lat: 29.1272220611573 },
  { lng: -80.9763870239257, lat: 29.1272220611573 },
  { lng: -80.9761123657226, lat: 29.1275005340577 },
  { lng: -80.9761123657226, lat: 29.1288890838623 },
  { lng: -80.9752807617188, lat: 29.1297225952148 },
  { lng: -80.974723815918, lat: 29.1297225952148 },
  { lng: -80.9744415283203, lat: 29.1294441223145 },
  { lng: -80.9741668701172, lat: 29.1297225952148 },
  { lng: -80.973892211914, lat: 29.1297225952148 },
  { lng: -80.9736862182617, lat: 29.12992477417 },
  { lng: -80.9736099243163, lat: 29.1299991607667 },
  { lng: -80.9730529785156, lat: 29.1299991607667 },
  { lng: -80.9727783203124, lat: 29.130277633667 },
  { lng: -80.9716644287109, lat: 29.130277633667 },
  { lng: -80.9713897705078, lat: 29.1305561065674 },
  { lng: -80.970832824707, lat: 29.1305561065674 },
  { lng: -80.9705581665038, lat: 29.1308326721192 },
  { lng: -80.9705581665038, lat: 29.1311111450196 },
  { lng: -80.9711074829102, lat: 29.1316661834717 },
  { lng: -80.9716644287109, lat: 29.1316661834717 },
  { lng: -80.9719467163086, lat: 29.1319446563721 },
  { lng: -80.9713897705078, lat: 29.1319446563721 },
  { lng: -80.9716644287109, lat: 29.1322231292725 },
  { lng: -80.9713897705078, lat: 29.1324996948243 },
  { lng: -80.9713897705078, lat: 29.1327762603759 },
  { lng: -80.9711074829102, lat: 29.1330547332765 },
  { lng: -80.9711074829102, lat: 29.1333332061769 },
  { lng: -80.970832824707, lat: 29.1336116790773 },
  { lng: -80.970832824707, lat: 29.1341667175292 },
  { lng: -80.9705581665038, lat: 29.1344451904296 },
  { lng: -80.9705581665038, lat: 29.1349983215333 },
  { lng: -80.970832824707, lat: 29.135280609131 },
  { lng: -80.970832824707, lat: 29.1358337402344 },
  { lng: -80.9711074829102, lat: 29.1361083984376 },
  { lng: -80.9711074829102, lat: 29.136667251587 },
  { lng: -80.9713897705078, lat: 29.1369438171387 },
  { lng: -80.9713897705078, lat: 29.1375026702881 },
  { lng: -80.9730529785156, lat: 29.1391658782958 },
  { lng: -80.9730529785156, lat: 29.1394443511964 },
  { lng: -80.9727783203124, lat: 29.1397247314454 },
  { lng: -80.9730529785156, lat: 29.1399993896484 },
  { lng: -80.9730529785156, lat: 29.1402778625488 },
  { lng: -80.9736099243163, lat: 29.1408348083496 },
  { lng: -80.973892211914, lat: 29.1408348083496 },
  { lng: -80.9741668701172, lat: 29.1405563354492 },
  { lng: -80.9744415283203, lat: 29.1405563354492 },
  { lng: -80.974723815918, lat: 29.1402778625488 },
  { lng: -80.9749984741211, lat: 29.1402778625488 },
  { lng: -80.9758300781249, lat: 29.1411113739014 },
  { lng: -80.9758300781249, lat: 29.1416664123535 },
  { lng: -80.9775009155273, lat: 29.1433334350587 },
  { lng: -80.9769439697265, lat: 29.1438884735109 },
  { lng: -80.9766693115234, lat: 29.143611907959 },
  { lng: -80.974723815918, lat: 29.143611907959 },
  { lng: -80.9744415283203, lat: 29.1433334350587 },
  { lng: -80.9741668701172, lat: 29.1433334350587 },
  { lng: -80.973892211914, lat: 29.1430568695069 },
  { lng: -80.9736099243163, lat: 29.1430568695069 },
  { lng: -80.9727783203124, lat: 29.1422214508057 },
  { lng: -80.9727783203124, lat: 29.1419448852539 },
  { lng: -80.970832824707, lat: 29.1399993896484 },
  { lng: -80.970832824707, lat: 29.1397247314454 },
  { lng: -80.9705581665038, lat: 29.1394443511964 },
  { lng: -80.9705581665038, lat: 29.1391658782958 },
  { lng: -80.9702758789062, lat: 29.1388893127443 },
  { lng: -80.9702758789062, lat: 29.1380558013917 },
  { lng: -80.9697189331054, lat: 29.1375026702881 },
  { lng: -80.9697189331054, lat: 29.1369438171387 },
  { lng: -80.9691696166992, lat: 29.1363906860352 },
  { lng: -80.9691696166992, lat: 29.1358337402344 },
  { lng: -80.9688873291016, lat: 29.135555267334 },
  { lng: -80.9688873291016, lat: 29.1341667175292 },
  { lng: -80.9691696166992, lat: 29.1338882446289 },
  { lng: -80.9688873291016, lat: 29.1336116790773 },
  { lng: -80.9688873291016, lat: 29.1333332061769 },
  { lng: -80.9686126708984, lat: 29.1330547332765 },
  { lng: -80.9686126708984, lat: 29.1327762603759 },
  { lng: -80.9683303833008, lat: 29.1324996948243 },
  { lng: -80.9683303833008, lat: 29.1322231292725 },
  { lng: -80.9680557250977, lat: 29.1319446563721 },
  { lng: -80.9680557250977, lat: 29.1316661834717 },
  { lng: -80.9677810668945, lat: 29.13138961792 },
  { lng: -80.9677810668945, lat: 29.1305561065674 },
  { lng: -80.9672241210937, lat: 29.1299991607667 },
  { lng: -80.9672241210937, lat: 29.1294441223145 },
  { lng: -80.9674987792969, lat: 29.1291675567627 },
  { lng: -80.9674987792969, lat: 29.1286106109619 },
  { lng: -80.9672241210937, lat: 29.1283359527589 },
  { lng: -80.9672241210937, lat: 29.1266670227052 },
  { lng: -80.966941833496, lat: 29.1263885498048 },
  { lng: -80.966941833496, lat: 29.1261138916016 },
  { lng: -80.9666671752929, lat: 29.1258335113526 },
  { lng: -80.9666671752929, lat: 29.1250019073486 },
  { lng: -80.9663925170897, lat: 29.1247234344482 },
  { lng: -80.9663925170897, lat: 29.1241664886475 },
  { lng: -80.9661102294921, lat: 29.1238880157471 },
  { lng: -80.9661102294921, lat: 29.1230564117431 },
  { lng: -80.9655532836914, lat: 29.1224994659424 },
  { lng: -80.9655532836914, lat: 29.1222229003907 },
  { lng: -80.9652786254883, lat: 29.1219444274903 },
  { lng: -80.9652786254883, lat: 29.1213874816894 },
  { lng: -80.9655532836914, lat: 29.1211109161378 },
  { lng: -80.9655532836914, lat: 29.1208324432374 },
  { lng: -80.9658355712891, lat: 29.1205558776856 },
  { lng: -80.9655532836914, lat: 29.1202774047853 },
  { lng: -80.9655532836914, lat: 29.1197223663331 },
  { lng: -80.9652786254883, lat: 29.1194438934327 },
  { lng: -80.9652786254883, lat: 29.1191673278809 },
  { lng: -80.9649963378906, lat: 29.1188888549805 },
  { lng: -80.9649963378906, lat: 29.1186103820801 },
  { lng: -80.9636077880859, lat: 29.1172218322754 },
  { lng: -80.9633331298828, lat: 29.1172218322754 },
  { lng: -80.962776184082, lat: 29.1177768707275 },
  { lng: -80.962776184082, lat: 29.1183338165283 },
  { lng: -80.9630584716796, lat: 29.1186103820801 },
  { lng: -80.9630584716796, lat: 29.1188888549805 },
  { lng: -80.9636077880859, lat: 29.1194438934327 },
  { lng: -80.9633331298828, lat: 29.1197223663331 },
  { lng: -80.9633331298828, lat: 29.1205558776856 },
  { lng: -80.9630584716796, lat: 29.1205558776856 },
  { lng: -80.962776184082, lat: 29.1202774047853 },
  { lng: -80.962776184082, lat: 29.1200008392335 },
  { lng: -80.9619445800781, lat: 29.1191673278809 },
  { lng: -80.9619445800781, lat: 29.1188888549805 },
  { lng: -80.9605560302734, lat: 29.1175003051758 },
  { lng: -80.9605560302734, lat: 29.1172218322754 },
  { lng: -80.9602813720703, lat: 29.1169452667236 },
  { lng: -80.9602813720703, lat: 29.1166667938232 },
  { lng: -80.9597244262695, lat: 29.1161117553712 },
  { lng: -80.9597244262695, lat: 29.1158332824708 },
  { lng: -80.9594421386719, lat: 29.1155567169191 },
  { lng: -80.9594421386719, lat: 29.1152782440187 },
  { lng: -80.9588851928711, lat: 29.1147212982179 },
  { lng: -80.9588851928711, lat: 29.1141662597657 },
  { lng: -80.958610534668, lat: 29.1138896942139 },
  { lng: -80.958610534668, lat: 29.1136112213135 },
  { lng: -80.9574966430663, lat: 29.1124992370606 },
  { lng: -80.9572219848632, lat: 29.1124992370606 },
  { lng: -80.9563903808594, lat: 29.111665725708 },
  { lng: -80.9563903808594, lat: 29.1102771759033 },
  { lng: -80.9555587768555, lat: 29.1094436645509 },
  { lng: -80.9555587768555, lat: 29.1086101531984 },
  { lng: -80.954719543457, lat: 29.107780456543 },
  { lng: -80.954719543457, lat: 29.1072216033936 },
  { lng: -80.9541702270507, lat: 29.1066665649414 },
  { lng: -80.9541702270507, lat: 29.1049995422363 },
  { lng: -80.9538879394531, lat: 29.1047229766847 },
  { lng: -80.9538879394531, lat: 29.1044445037843 },
  { lng: -80.9533309936522, lat: 29.1038894653321 },
  { lng: -80.9533309936522, lat: 29.1033325195314 },
  { lng: -80.9530563354492, lat: 29.103054046631 },
  { lng: -80.9530563354492, lat: 29.1027774810792 },
  { lng: -80.9527740478516, lat: 29.1025009155274 },
  { lng: -80.9527740478516, lat: 29.102222442627 },
  { lng: -80.9524993896484, lat: 29.1019458770752 },
  { lng: -80.9524993896484, lat: 29.1016674041749 },
  { lng: -80.9522247314453, lat: 29.1013889312745 },
  { lng: -80.9522247314453, lat: 29.1008358001708 },
  { lng: -80.9519424438477, lat: 29.1005554199219 },
  { lng: -80.9519424438477, lat: 29.1000003814697 },
  { lng: -80.9511108398437, lat: 29.0991668701171 },
  { lng: -80.9511108398437, lat: 29.0988883972168 },
  { lng: -80.9497222900391, lat: 29.0975017547608 },
  { lng: -80.9497222900391, lat: 29.0972194671631 },
  { lng: -80.9502792358397, lat: 29.0966663360596 },
  { lng: -80.9502792358397, lat: 29.0949993133545 },
  { lng: -80.9497222900391, lat: 29.0944442749023 },
  { lng: -80.9497222900391, lat: 29.0938873291016 },
  { lng: -80.9499969482422, lat: 29.0936107635498 },
  { lng: -80.9499969482422, lat: 29.0933341979982 },
  { lng: -80.9502792358397, lat: 29.0930557250976 },
  { lng: -80.9502792358397, lat: 29.0919685363771 },
  { lng: -80.9502792358397, lat: 29.0916652679444 },
  { lng: -80.9505538940429, lat: 29.0913887023927 },
  { lng: -80.9505538940429, lat: 29.0902786254883 },
  { lng: -80.9508361816406, lat: 29.0900001525879 },
  { lng: -80.9508361816406, lat: 29.0886116027832 },
  { lng: -80.9511108398437, lat: 29.0883331298828 },
  { lng: -80.9513854980468, lat: 29.0883331298828 },
  { lng: -80.9516677856445, lat: 29.0880546569824 },
  { lng: -80.9527740478516, lat: 29.0880546569824 },
  { lng: -80.9530563354492, lat: 29.0883331298828 },
  { lng: -80.9533309936522, lat: 29.0883331298828 },
  { lng: -80.9536132812499, lat: 29.0886116027832 },
  { lng: -80.9538879394531, lat: 29.0886116027832 },
  { lng: -80.9541702270507, lat: 29.088888168335 },
  { lng: -80.9566650390624, lat: 29.088888168335 },
  { lng: -80.9569473266601, lat: 29.0891666412354 },
  { lng: -80.9572219848632, lat: 29.088888168335 },
  { lng: -80.957778930664, lat: 29.088888168335 },
  { lng: -80.958610534668, lat: 29.0880546569824 },
  { lng: -80.9591674804688, lat: 29.0880546569824 },
  { lng: -80.9594421386719, lat: 29.0877780914307 },
  { lng: -80.9597244262695, lat: 29.0877780914307 },
  { lng: -80.9599990844726, lat: 29.0874996185303 },
  { lng: -80.9605560302734, lat: 29.0880546569824 },
  { lng: -80.9606170654297, lat: 29.0886058807374 },
  { lng: -80.9606246948242, lat: 29.0886821746826 },
  { lng: -80.9608306884765, lat: 29.088888168335 },
  { lng: -80.9608306884765, lat: 29.0891666412354 },
  { lng: -80.9622192382812, lat: 29.0905551910401 },
  { lng: -80.9625015258789, lat: 29.0905551910401 },
  { lng: -80.9630584716796, lat: 29.0900001525879 },
  { lng: -80.9636077880859, lat: 29.0900001525879 },
  { lng: -80.9638900756835, lat: 29.0902786254883 },
  { lng: -80.9644470214844, lat: 29.0897216796876 },
  { lng: -80.9647216796875, lat: 29.0897216796876 },
  { lng: -80.9649963378906, lat: 29.0894451141357 },
  { lng: -80.9652786254883, lat: 29.0894451141357 },
  { lng: -80.9655532836914, lat: 29.0891666412354 },
  { lng: -80.9661102294921, lat: 29.0891666412354 },
  { lng: -80.9663925170897, lat: 29.088888168335 },
  { lng: -80.9666671752929, lat: 29.088888168335 },
  { lng: -80.966941833496, lat: 29.0886116027832 },
  { lng: -80.9672241210937, lat: 29.0886116027832 },
  { lng: -80.9677810668945, lat: 29.0880546569824 },
  { lng: -80.9677810668945, lat: 29.0877780914307 },
  { lng: -80.9683303833008, lat: 29.0872230529786 },
  { lng: -80.9686126708984, lat: 29.0874996185303 },
  { lng: -80.9688873291016, lat: 29.0874996185303 },
  { lng: -80.9691696166992, lat: 29.0872230529786 },
  { lng: -80.9694442749022, lat: 29.0872230529786 },
  { lng: -80.9697189331054, lat: 29.0874996185303 },
  { lng: -80.9697189331054, lat: 29.0877780914307 },
  { lng: -80.9713897705078, lat: 29.0894451141357 },
  { lng: -80.9713897705078, lat: 29.0900001525879 },
  { lng: -80.9711074829102, lat: 29.0902786254883 },
  { lng: -80.9715576171875, lat: 29.0903968811036 },
  { lng: -80.9717025756835, lat: 29.0906448364258 },
  { lng: -80.9718627929688, lat: 29.0909061431885 },
  { lng: -80.9719924926757, lat: 29.0912094116211 },
  { lng: -80.9722213745117, lat: 29.0914554595947 },
  { lng: -80.9724731445312, lat: 29.0916900634767 },
  { lng: -80.9727706909179, lat: 29.0918941497803 },
  { lng: -80.9731521606445, lat: 29.0919418334961 },
  { lng: -80.9735641479492, lat: 29.0919628143311 },
  { lng: -80.9739532470703, lat: 29.0920429229737 },
  { lng: -80.9742965698242, lat: 29.0922183990479 },
  { lng: -80.9746246337891, lat: 29.0924224853516 },
  { lng: -80.974884033203, lat: 29.0927352905273 },
  { lng: -80.9750671386718, lat: 29.0932445526123 },
  { lng: -80.974723815918, lat: 29.0936107635498 },
  { lng: -80.9744415283203, lat: 29.0938873291016 },
  { lng: -80.9741668701172, lat: 29.0938873291016 },
  { lng: -80.973892211914, lat: 29.094165802002 },
  { lng: -80.973892211914, lat: 29.0944442749023 },
  { lng: -80.974021911621, lat: 29.0945739746095 },
  { lng: -80.9744415283203, lat: 29.0949993133545 },
  { lng: -80.9752807617188, lat: 29.0949993133545 },
  { lng: -80.9755554199219, lat: 29.0947227478027 },
  { lng: -80.9758300781249, lat: 29.0947227478027 },
  { lng: -80.9763870239257, lat: 29.094165802002 },
  { lng: -80.9766159057617, lat: 29.0940761566163 },
  { lng: -80.976921081543, lat: 29.093885421753 },
  { lng: -80.9771652221679, lat: 29.0936164855958 },
  { lng: -80.9772796630859, lat: 29.0932426452637 },
  { lng: -80.9772491455078, lat: 29.0928897857667 },
  { lng: -80.9769897460938, lat: 29.0925846099854 },
  { lng: -80.9765853881835, lat: 29.0923824310304 },
  { lng: -80.9762344360352, lat: 29.0921096801757 },
  { lng: -80.9759216308593, lat: 29.091775894165 },
  { lng: -80.9755783081055, lat: 29.0914344787597 },
  { lng: -80.9751586914062, lat: 29.091121673584 },
  { lng: -80.974739074707, lat: 29.0907955169678 },
  { lng: -80.9744415283203, lat: 29.0903587341309 },
  { lng: -80.9741668701172, lat: 29.0898952484131 },
  { lng: -80.9738998413085, lat: 29.0894298553467 },
  { lng: -80.9736557006836, lat: 29.0889587402344 },
  { lng: -80.9734344482421, lat: 29.088508605957 },
  { lng: -80.9734268188476, lat: 29.0879993438721 },
  { lng: -80.9736251831054, lat: 29.0874938964845 },
  { lng: -80.9739227294921, lat: 29.0870227813721 },
  { lng: -80.9742431640625, lat: 29.0865688323975 },
  { lng: -80.9745635986328, lat: 29.0860118865967 },
  { lng: -80.9748992919922, lat: 29.0854053497315 },
  { lng: -80.9753341674804, lat: 29.0848178863526 },
  { lng: -80.9758377075194, lat: 29.0842971801758 },
  { lng: -80.9763717651367, lat: 29.0838241577149 },
  { lng: -80.976921081543, lat: 29.0834197998047 },
  { lng: -80.9776077270508, lat: 29.0832672119141 },
  { lng: -80.9782409667969, lat: 29.0830516815187 },
  { lng: -80.9788360595703, lat: 29.0828914642335 },
  { lng: -80.9793930053711, lat: 29.0830593109132 },
  { lng: -80.9799118041992, lat: 29.0833110809326 },
  { lng: -80.9804306030273, lat: 29.0834789276122 },
  { lng: -80.9809265136719, lat: 29.0835018157959 },
  { lng: -80.9813385009765, lat: 29.0831794738771 },
  { lng: -80.9816436767578, lat: 29.0827045440674 },
  { lng: -80.9818725585938, lat: 29.0822048187256 },
  { lng: -80.982063293457, lat: 29.081729888916 },
  { lng: -80.982322692871, lat: 29.081382751465 },
  { lng: -80.982551574707, lat: 29.0810604095459 },
  { lng: -80.9827499389648, lat: 29.0807399749756 },
  { lng: -80.9827728271484, lat: 29.0803527832032 },
  { lng: -80.9827270507812, lat: 29.0799999237062 },
  { lng: -80.9825744628906, lat: 29.079746246338 },
  { lng: -80.9827804565429, lat: 29.079444885254 },
  { lng: -80.98388671875, lat: 29.0795097351075 },
  { lng: -80.9838714599609, lat: 29.0801963806152 },
  { lng: -80.9846878051757, lat: 29.0805644989015 },
  { lng: -80.9852447509765, lat: 29.0803661346437 },
  { lng: -80.9857025146484, lat: 29.0800457000732 },
  { lng: -80.986083984375, lat: 29.0795745849609 },
  { lng: -80.9863967895507, lat: 29.0790824890137 },
  { lng: -80.9866943359374, lat: 29.0785751342773 },
  { lng: -80.9869689941406, lat: 29.0780448913575 },
  { lng: -80.9865036010742, lat: 29.0775108337402 },
  { lng: -80.9866256713867, lat: 29.0766658782959 },
  { lng: -80.9873886108398, lat: 29.0762519836426 },
  { lng: -80.9878234863281, lat: 29.0758571624756 },
  { lng: -80.9881286621093, lat: 29.0754356384277 },
  { lng: -80.9883880615234, lat: 29.0748348236085 },
  { lng: -80.9888916015624, lat: 29.0742568969727 },
  { lng: -80.9891662597656, lat: 29.073865890503 },
  { lng: -80.9893646240234, lat: 29.0734443664551 },
  { lng: -80.9892044067383, lat: 29.0729885101318 },
  { lng: -80.9888076782225, lat: 29.0733337402344 },
  { lng: -80.988410949707, lat: 29.0736713409424 },
  { lng: -80.9880676269531, lat: 29.0740566253663 },
  { lng: -80.9877548217772, lat: 29.0744724273682 },
  { lng: -80.987449645996, lat: 29.0749015808105 },
  { lng: -80.9870834350585, lat: 29.0752544403077 },
  { lng: -80.9866790771484, lat: 29.0755653381349 },
  { lng: -80.9863052368163, lat: 29.0759353637695 },
  { lng: -80.9858322143554, lat: 29.0762252807618 },
  { lng: -80.9853820800781, lat: 29.0768013000488 },
  { lng: -80.9846343994141, lat: 29.0770721435547 },
  { lng: -80.9843826293945, lat: 29.0775222778321 },
  { lng: -80.9841232299804, lat: 29.0778350830078 },
  { lng: -80.9838790893555, lat: 29.0781345367432 },
  { lng: -80.983528137207, lat: 29.0783920288086 },
  { lng: -80.9828567504882, lat: 29.0785789489747 },
  { lng: -80.9822463989258, lat: 29.0788917541504 },
  { lng: -80.9816665649414, lat: 29.0792331695557 },
  { lng: -80.9810791015624, lat: 29.0795402526856 },
  { lng: -80.98046875, lat: 29.0796985626222 },
  { lng: -80.9798583984375, lat: 29.0796966552736 },
  { lng: -80.9792785644531, lat: 29.0795650482178 },
  { lng: -80.9787368774414, lat: 29.0795078277589 },
  { lng: -80.9782257080078, lat: 29.0796718597412 },
  { lng: -80.9776763916016, lat: 29.0797214508058 },
  { lng: -80.9771194458008, lat: 29.0797691345216 },
  { lng: -80.976577758789, lat: 29.0799407958984 },
  { lng: -80.9759826660156, lat: 29.0798492431642 },
  { lng: -80.9754104614258, lat: 29.0795650482178 },
  { lng: -80.974868774414, lat: 29.0792140960694 },
  { lng: -80.9742660522461, lat: 29.0789546966553 },
  { lng: -80.9736328124999, lat: 29.0788345336915 },
  { lng: -80.9730911254883, lat: 29.0786170959474 },
  { lng: -80.9725723266601, lat: 29.0783767700195 },
  { lng: -80.9721221923828, lat: 29.0780696868897 },
  { lng: -80.9716644287109, lat: 29.0778045654296 },
  { lng: -80.9711837768555, lat: 29.0775928497315 },
  { lng: -80.9708099365234, lat: 29.0772647857667 },
  { lng: -80.9704589843749, lat: 29.0768852233888 },
  { lng: -80.9702072143554, lat: 29.0764160156251 },
  { lng: -80.9699172973632, lat: 29.0759353637695 },
  { lng: -80.9695281982421, lat: 29.075496673584 },
  { lng: -80.969139099121, lat: 29.0750064849854 },
  { lng: -80.968780517578, lat: 29.0745124816895 },
  { lng: -80.968765258789, lat: 29.0740108489991 },
  { lng: -80.9688262939453, lat: 29.0734596252443 },
  { lng: -80.9686813354491, lat: 29.0729122161866 },
  { lng: -80.968620300293, lat: 29.0723285675049 },
  { lng: -80.9686126708984, lat: 29.0717201232911 },
  { lng: -80.9685974121094, lat: 29.0711135864259 },
  { lng: -80.968505859375, lat: 29.0705356597901 },
  { lng: -80.968276977539, lat: 29.0700683593751 },
  { lng: -80.9684982299805, lat: 29.069595336914 },
  { lng: -80.9688873291016, lat: 29.0691356658937 },
  { lng: -80.9691848754883, lat: 29.0685787200929 },
  { lng: -80.9692993164062, lat: 29.0680103302003 },
  { lng: -80.9690704345703, lat: 29.0675563812256 },
  { lng: -80.9687118530273, lat: 29.0671710968017 },
  { lng: -80.9683609008788, lat: 29.0667533874512 },
  { lng: -80.9680557250977, lat: 29.0662803649902 },
  { lng: -80.9677734375, lat: 29.0657901763917 },
  { lng: -80.9674758911133, lat: 29.0653095245361 },
  { lng: -80.9671173095703, lat: 29.0648784637452 },
  { lng: -80.9668273925781, lat: 29.0643939971924 },
  { lng: -80.9666519165038, lat: 29.0638446807862 },
  { lng: -80.9664611816406, lat: 29.0632915496826 },
  { lng: -80.9663391113281, lat: 29.0627384185792 },
  { lng: -80.9660339355469, lat: 29.0623054504395 },
  { lng: -80.9656219482421, lat: 29.0620021820069 },
  { lng: -80.9652252197265, lat: 29.0616893768312 },
  { lng: -80.9648437499999, lat: 29.0613746643067 },
  { lng: -80.964599609375, lat: 29.0610084533692 },
  { lng: -80.9647369384765, lat: 29.0604877471923 },
  { lng: -80.9649963378906, lat: 29.0599346160889 },
  { lng: -80.9652099609375, lat: 29.0593757629396 },
  { lng: -80.9651336669921, lat: 29.0587997436524 },
  { lng: -80.9648132324219, lat: 29.0583038330079 },
  { lng: -80.9644851684569, lat: 29.0578117370607 },
  { lng: -80.9641723632812, lat: 29.0573177337648 },
  { lng: -80.963882446289, lat: 29.0568294525146 },
  { lng: -80.9636001586913, lat: 29.0563545227051 },
  { lng: -80.9634323120117, lat: 29.0558376312256 },
  { lng: -80.963523864746, lat: 29.0552005767822 },
  { lng: -80.9635314941405, lat: 29.054557800293 },
  { lng: -80.9635391235352, lat: 29.0539703369141 },
  { lng: -80.9633178710937, lat: 29.053472518921 },
  { lng: -80.9629974365234, lat: 29.0530834197999 },
  { lng: -80.9626007080078, lat: 29.0528259277344 },
  { lng: -80.9621505737305, lat: 29.0526847839357 },
  { lng: -80.9617538452148, lat: 29.0524997711182 },
  { lng: -80.9613571166992, lat: 29.052339553833 },
  { lng: -80.9609222412109, lat: 29.0523052215577 },
  { lng: -80.9605560302734, lat: 29.0519447326661 },
  { lng: -80.9602813720703, lat: 29.0522212982178 },
  { lng: -80.9599990844726, lat: 29.0524997711182 },
  { lng: -80.9597244262695, lat: 29.0524997711182 },
  { lng: -80.9597091674804, lat: 29.0529079437257 },
  { lng: -80.9593200683593, lat: 29.0532321929933 },
  { lng: -80.9592971801758, lat: 29.0534839630127 },
  { lng: -80.9595260620116, lat: 29.0537014007569 },
  { lng: -80.9597778320312, lat: 29.0539684295654 },
  { lng: -80.9600219726562, lat: 29.0543136596681 },
  { lng: -80.9602890014647, lat: 29.0546531677246 },
  { lng: -80.9605407714843, lat: 29.0549926757813 },
  { lng: -80.9607391357421, lat: 29.0553722381592 },
  { lng: -80.960823059082, lat: 29.0558280944824 },
  { lng: -80.9608612060546, lat: 29.0563163757325 },
  { lng: -80.9609832763672, lat: 29.0568161010742 },
  { lng: -80.9611053466797, lat: 29.0573387145996 },
  { lng: -80.9612121582031, lat: 29.0578575134278 },
  { lng: -80.9613494873047, lat: 29.0583438873292 },
  { lng: -80.9613876342773, lat: 29.0589065551759 },
  { lng: -80.9614334106444, lat: 29.0594825744629 },
  { lng: -80.9615783691406, lat: 29.0600547790528 },
  { lng: -80.9614334106444, lat: 29.0605754852296 },
  { lng: -80.9613952636719, lat: 29.0611438751221 },
  { lng: -80.96134185791, lat: 29.061716079712 },
  { lng: -80.9611206054687, lat: 29.0622463226319 },
  { lng: -80.9609680175781, lat: 29.0628242492675 },
  { lng: -80.9608535766601, lat: 29.063440322876 },
  { lng: -80.9608688354492, lat: 29.0640754699708 },
  { lng: -80.961067199707, lat: 29.0646495819092 },
  { lng: -80.9613952636719, lat: 29.0651226043702 },
  { lng: -80.9617538452148, lat: 29.0655612945557 },
  { lng: -80.9621124267578, lat: 29.0660037994386 },
  { lng: -80.9624633789062, lat: 29.0664348602295 },
  { lng: -80.9627609252929, lat: 29.0669021606446 },
  { lng: -80.9629669189453, lat: 29.0674324035645 },
  { lng: -80.9630889892578, lat: 29.0679836273194 },
  { lng: -80.9633483886719, lat: 29.0684852600098 },
  { lng: -80.9636535644531, lat: 29.0689640045167 },
  { lng: -80.9639053344726, lat: 29.0695133209229 },
  { lng: -80.9641723632812, lat: 29.0700550079346 },
  { lng: -80.9644470214844, lat: 29.0705909729005 },
  { lng: -80.9647216796875, lat: 29.0711231231691 },
  { lng: -80.9649887084961, lat: 29.0716629028321 },
  { lng: -80.9652252197265, lat: 29.0722064971924 },
  { lng: -80.9652786254883, lat: 29.0728130340576 },
  { lng: -80.9652786254883, lat: 29.0734386444092 },
  { lng: -80.965232849121, lat: 29.0740661621094 },
  { lng: -80.9650497436523, lat: 29.0746898651124 },
  { lng: -80.9650115966797, lat: 29.0753345489503 },
  { lng: -80.9650650024414, lat: 29.0759658813477 },
  { lng: -80.9652786254883, lat: 29.0765342712403 },
  { lng: -80.9655303955078, lat: 29.0770854949952 },
  { lng: -80.9657440185547, lat: 29.0776443481445 },
  { lng: -80.9660568237305, lat: 29.0780792236329 },
  { lng: -80.9663772583007, lat: 29.0785140991212 },
  { lng: -80.9666671752929, lat: 29.078987121582 },
  { lng: -80.9669036865234, lat: 29.0794792175293 },
  { lng: -80.9669723510742, lat: 29.0799961090088 },
  { lng: -80.9671478271484, lat: 29.0804576873779 },
  { lng: -80.9674377441406, lat: 29.0808143615723 },
  { lng: -80.967788696289, lat: 29.0810985565186 },
  { lng: -80.9681015014648, lat: 29.0813484191895 },
  { lng: -80.9680099487304, lat: 29.081699371338 },
  { lng: -80.9677810668945, lat: 29.0820865631104 },
  { lng: -80.9674987792969, lat: 29.0824642181398 },
  { lng: -80.9671478271484, lat: 29.0827999114991 },
  { lng: -80.9666061401367, lat: 29.0829887390138 },
  { lng: -80.965934753418, lat: 29.083013534546 },
  { lng: -80.965232849121, lat: 29.0829257965088 },
  { lng: -80.9645004272461, lat: 29.0829467773437 },
  { lng: -80.963752746582, lat: 29.0826759338379 },
  { lng: -80.963005065918, lat: 29.0823955535889 },
  { lng: -80.9623260498047, lat: 29.0821228027344 },
  { lng: -80.9618988037109, lat: 29.0818195343019 },
  { lng: -80.9616088867187, lat: 29.0814914703369 },
  { lng: -80.961296081543, lat: 29.0814094543458 },
  { lng: -80.9609985351562, lat: 29.0814514160156 },
  { lng: -80.9607009887695, lat: 29.0815849304199 },
  { lng: -80.9603652954101, lat: 29.0816802978516 },
  { lng: -80.9600219726562, lat: 29.0818004608154 },
  { lng: -80.9596939086913, lat: 29.0819301605226 },
  { lng: -80.9593505859375, lat: 29.0819664001465 },
  { lng: -80.9589996337891, lat: 29.0817604064941 },
  { lng: -80.9586639404296, lat: 29.0813541412354 },
  { lng: -80.9583282470703, lat: 29.0809116363525 },
  { lng: -80.9579238891602, lat: 29.0805110931397 },
  { lng: -80.9573440551758, lat: 29.0802688598634 },
  { lng: -80.9567565917968, lat: 29.0800228118898 },
  { lng: -80.9569473266601, lat: 29.0802783966066 },
  { lng: -80.9563903808594, lat: 29.0808334350587 },
  { lng: -80.9561080932617, lat: 29.0811119079591 },
  { lng: -80.9538879394531, lat: 29.0811119079591 },
  { lng: -80.9533309936522, lat: 29.0805549621583 },
  { lng: -80.9533309936522, lat: 29.0802783966066 },
  { lng: -80.9530563354492, lat: 29.0799999237062 },
  { lng: -80.9527740478516, lat: 29.0797214508058 },
  { lng: -80.9524993896484, lat: 29.0797214508058 },
  { lng: -80.9523468017578, lat: 29.0800037384033 },
  { lng: -80.9522247314453, lat: 29.0803565979005 },
  { lng: -80.9521331787109, lat: 29.080722808838 },
  { lng: -80.9519958496093, lat: 29.0810432434083 },
  { lng: -80.9518966674804, lat: 29.0813674926759 },
  { lng: -80.9517059326171, lat: 29.081657409668 },
  { lng: -80.9516677856445, lat: 29.0819435119629 },
  { lng: -80.9519424438477, lat: 29.0822200775147 },
  { lng: -80.9519424438477, lat: 29.0824985504151 },
  { lng: -80.9524993896484, lat: 29.0830554962158 },
  { lng: -80.9527740478516, lat: 29.0830554962158 },
  { lng: -80.9533309936522, lat: 29.0824985504151 },
  { lng: -80.954719543457, lat: 29.0824985504151 },
  { lng: -80.9550018310547, lat: 29.0822200775147 },
  { lng: -80.9552764892578, lat: 29.0824985504151 },
  { lng: -80.9569473266601, lat: 29.0824985504151 },
  { lng: -80.9572219848632, lat: 29.0827770233154 },
  { lng: -80.9574966430663, lat: 29.0827770233154 },
  { lng: -80.957778930664, lat: 29.0830554962158 },
  { lng: -80.957778930664, lat: 29.083890914917 },
  { lng: -80.9580535888672, lat: 29.0841674804688 },
  { lng: -80.957778930664, lat: 29.084442138672 },
  { lng: -80.957778930664, lat: 29.0849990844728 },
  { lng: -80.9580535888672, lat: 29.0852775573732 },
  { lng: -80.957778930664, lat: 29.0855560302735 },
  { lng: -80.957778930664, lat: 29.0861110687257 },
  { lng: -80.9572219848632, lat: 29.0866661071777 },
  { lng: -80.9569473266601, lat: 29.0869445800782 },
  { lng: -80.9563903808594, lat: 29.0874996185303 },
  { lng: -80.9561080932617, lat: 29.0874996185303 },
  { lng: -80.9558334350586, lat: 29.0877780914307 },
  { lng: -80.9550018310547, lat: 29.0877780914307 },
  { lng: -80.954719543457, lat: 29.0874996185303 },
  { lng: -80.9544448852538, lat: 29.0872230529786 },
  { lng: -80.9541702270507, lat: 29.0869445800782 },
  { lng: -80.9536132812499, lat: 29.0863895416261 },
  { lng: -80.9522247314453, lat: 29.0863895416261 },
  { lng: -80.9519424438477, lat: 29.0866661071777 },
  { lng: -80.9505538940429, lat: 29.0866661071777 },
  { lng: -80.9497222900391, lat: 29.0858306884766 },
  { lng: -80.9494476318359, lat: 29.0855560302735 },
  { lng: -80.9488906860352, lat: 29.0849990844728 },
  { lng: -80.9488906860352, lat: 29.084722518921 },
  { lng: -80.9486083984375, lat: 29.084442138672 },
  { lng: -80.9486083984375, lat: 29.0841674804688 },
  { lng: -80.9483337402343, lat: 29.083890914917 },
  { lng: -80.9483337402343, lat: 29.0836124420166 },
  { lng: -80.9480590820312, lat: 29.0833339691162 },
  { lng: -80.9480743408203, lat: 29.0829944610595 },
  { lng: -80.9479370117188, lat: 29.0827922821045 },
  { lng: -80.9477996826171, lat: 29.0825691223145 },
  { lng: -80.9476928710937, lat: 29.0823097229004 },
  { lng: -80.9475250244141, lat: 29.08207321167 },
  { lng: -80.9473648071289, lat: 29.08180809021 },
  { lng: -80.9471817016602, lat: 29.0815353393555 },
  { lng: -80.9469528198242, lat: 29.0812854766845 },
  { lng: -80.9467163085937, lat: 29.0810127258301 },
  { lng: -80.9465026855469, lat: 29.0806941986085 },
  { lng: -80.9462738037108, lat: 29.0803833007813 },
  { lng: -80.9461364746094, lat: 29.079963684082 },
  { lng: -80.9460525512695, lat: 29.0794811248779 },
  { lng: -80.9458160400391, lat: 29.0790328979493 },
  { lng: -80.9455184936523, lat: 29.0785942077637 },
  { lng: -80.9452209472655, lat: 29.0781288146973 },
  { lng: -80.9449310302734, lat: 29.0776767730713 },
  { lng: -80.944709777832, lat: 29.0771808624268 },
  { lng: -80.9444732666016, lat: 29.0766925811769 },
  { lng: -80.9442596435546, lat: 29.0762062072754 },
  { lng: -80.9440536499023, lat: 29.0757236480714 },
  { lng: -80.94384765625, lat: 29.0752429962158 },
  { lng: -80.9436340332031, lat: 29.0747795104981 },
  { lng: -80.9434585571289, lat: 29.0742816925049 },
  { lng: -80.9432983398437, lat: 29.0737476348878 },
  { lng: -80.9430541992188, lat: 29.0732421875 },
  { lng: -80.9427642822266, lat: 29.0727615356446 },
  { lng: -80.9424438476562, lat: 29.0723152160646 },
  { lng: -80.9420623779296, lat: 29.0719318389892 },
  { lng: -80.9417037963867, lat: 29.0715312957764 },
  { lng: -80.9414138793945, lat: 29.0711116790771 },
  { lng: -80.9411926269531, lat: 29.070671081543 },
  { lng: -80.9409027099609, lat: 29.0702781677246 },
  { lng: -80.9405899047852, lat: 29.0698871612549 },
  { lng: -80.940299987793, lat: 29.0694675445557 },
  { lng: -80.9400024414062, lat: 29.0690364837646 },
  { lng: -80.9397277832031, lat: 29.0685844421387 },
  { lng: -80.939468383789, lat: 29.0680999755859 },
  { lng: -80.9392623901367, lat: 29.0675506591797 },
  { lng: -80.939109802246, lat: 29.06693649292 },
  { lng: -80.9388580322266, lat: 29.0663356781006 },
  { lng: -80.9385375976562, lat: 29.0658073425294 },
  { lng: -80.9382171630859, lat: 29.0653800964356 },
  { lng: -80.9381637573241, lat: 29.0649452209473 },
  { lng: -80.9383468627929, lat: 29.0645275115967 },
  { lng: -80.9385681152344, lat: 29.0641174316406 },
  { lng: -80.9386978149414, lat: 29.0636768341066 },
  { lng: -80.9384536743163, lat: 29.0631065368653 },
  { lng: -80.9379806518555, lat: 29.0627632141114 },
  { lng: -80.9374694824218, lat: 29.0624542236329 },
  { lng: -80.9369735717773, lat: 29.0621280670167 },
  { lng: -80.9365158081054, lat: 29.061782836914 },
  { lng: -80.9360885620117, lat: 29.0614051818848 },
  { lng: -80.9356536865234, lat: 29.061061859131 },
  { lng: -80.9352798461913, lat: 29.0606899261475 },
  { lng: -80.934928894043, lat: 29.0603275299072 },
  { lng: -80.9345397949218, lat: 29.0600032806398 },
  { lng: -80.9341506958007, lat: 29.0597076416016 },
  { lng: -80.9337463378906, lat: 29.0594348907471 },
  { lng: -80.9333419799805, lat: 29.059175491333 },
  { lng: -80.9328994750976, lat: 29.0589694976806 },
  { lng: -80.9323272705078, lat: 29.0588989257814 },
  { lng: -80.9318313598633, lat: 29.0587196350098 },
  { lng: -80.9313354492188, lat: 29.0586223602296 },
  { lng: -80.9308547973633, lat: 29.0585556030273 },
  { lng: -80.9304199218749, lat: 29.0583515167237 },
  { lng: -80.9299926757812, lat: 29.0581836700441 },
  { lng: -80.9295578002929, lat: 29.058090209961 },
  { lng: -80.9291687011718, lat: 29.0580558776855 },
  { lng: -80.928611755371, lat: 29.0575027465821 },
  { lng: -80.928611755371, lat: 29.0569438934327 },
  { lng: -80.9277801513672, lat: 29.0561084747314 },
  { lng: -80.9269409179688, lat: 29.0569438934327 },
  { lng: -80.9263916015625, lat: 29.0569438934327 },
  { lng: -80.9259796142578, lat: 29.0569477081299 },
  { lng: -80.9256820678711, lat: 29.0569400787354 },
  { lng: -80.9253311157227, lat: 29.0569400787354 },
  { lng: -80.9249649047852, lat: 29.0568981170655 },
  { lng: -80.9246063232421, lat: 29.0567474365235 },
  { lng: -80.9241714477539, lat: 29.0566463470459 },
  { lng: -80.923713684082, lat: 29.056510925293 },
  { lng: -80.9231719970703, lat: 29.0564117431642 },
  { lng: -80.9225921630859, lat: 29.0563926696777 },
  { lng: -80.9219970703125, lat: 29.0564098358154 },
  { lng: -80.9214096069336, lat: 29.056510925293 },
  { lng: -80.9208374023438, lat: 29.0566368103028 },
  { lng: -80.9202575683594, lat: 29.0566654205323 },
  { lng: -80.919677734375, lat: 29.056661605835 },
  { lng: -80.9191131591797, lat: 29.0566139221192 },
  { lng: -80.9186096191406, lat: 29.0564975738525 },
  { lng: -80.9181594848633, lat: 29.0566082000732 },
  { lng: -80.9176940917969, lat: 29.0566539764405 },
  { lng: -80.917236328125, lat: 29.0566234588623 },
  { lng: -80.9168090820312, lat: 29.0564842224122 },
  { lng: -80.9163208007812, lat: 29.0564441680909 },
  { lng: -80.9157867431641, lat: 29.05645942688 },
  { lng: -80.9154663085936, lat: 29.0565280914307 },
  { lng: -80.9152603149413, lat: 29.0565738677979 },
  { lng: -80.9147338867188, lat: 29.0564117431642 },
  { lng: -80.9142227172851, lat: 29.0561981201173 },
  { lng: -80.9138870239258, lat: 29.0558338165284 },
  { lng: -80.9119415283202, lat: 29.0538883209229 },
  { lng: -80.9119415283202, lat: 29.0533332824707 },
  { lng: -80.9116668701172, lat: 29.0530548095703 },
  { lng: -80.9116668701172, lat: 29.0527763366699 },
  { lng: -80.9105529785156, lat: 29.0516662597656 },
  { lng: -80.9100036621093, lat: 29.0516662597656 },
  { lng: -80.9097213745117, lat: 29.051389694214 },
  { lng: -80.9102783203125, lat: 29.0508327484132 },
  { lng: -80.9102783203125, lat: 29.0502777099611 },
  { lng: -80.9100036621093, lat: 29.0499992370607 },
  { lng: -80.9100036621093, lat: 29.0497226715088 },
  { lng: -80.9097213745117, lat: 29.0494441986085 },
  { lng: -80.9097213745117, lat: 29.0491657257081 },
  { lng: -80.9102783203125, lat: 29.0486106872559 },
  { lng: -80.9094467163085, lat: 29.0477771759033 },
  { lng: -80.9094467163085, lat: 29.0475006103516 },
  { lng: -80.9088897705077, lat: 29.0469436645508 },
  { lng: -80.9088897705077, lat: 29.046667098999 },
  { lng: -80.9086074829102, lat: 29.0463886260986 },
  { lng: -80.9086074829102, lat: 29.0447235107422 },
  { lng: -80.908332824707, lat: 29.0444450378419 },
  { lng: -80.908332824707, lat: 29.0441665649415 },
  { lng: -80.9086074829102, lat: 29.0438880920411 },
  { lng: -80.9086074829102, lat: 29.0436096191407 },
  { lng: -80.9088897705077, lat: 29.0433311462403 },
  { lng: -80.9088897705077, lat: 29.0427799224854 },
  { lng: -80.908332824707, lat: 29.042221069336 },
  { lng: -80.908332824707, lat: 29.0419445037842 },
  { lng: -80.9080581665039, lat: 29.0416660308838 },
  { lng: -80.9080581665039, lat: 29.0411090850831 },
  { lng: -80.9077758789062, lat: 29.0408325195312 },
  { lng: -80.9077758789062, lat: 29.040277481079 },
  { lng: -80.9075012207031, lat: 29.0400009155275 },
  { lng: -80.9075012207031, lat: 29.0394439697267 },
  { lng: -80.9072189331055, lat: 29.0391674041749 },
  { lng: -80.9072189331055, lat: 29.0380554199219 },
  { lng: -80.9080581665039, lat: 29.0372219085694 },
  { lng: -80.9080581665039, lat: 29.0366668701172 },
  { lng: -80.908332824707, lat: 29.0363883972169 },
  { lng: -80.908332824707, lat: 29.0349998474121 },
  { lng: -80.9080581665039, lat: 29.0347213745117 },
  { lng: -80.9086074829102, lat: 29.0341663360595 },
  { lng: -80.9089050292969, lat: 29.0337448120117 },
  { lng: -80.9093551635742, lat: 29.0334072113038 },
  { lng: -80.9098892211914, lat: 29.033302307129 },
  { lng: -80.9104995727538, lat: 29.0334491729736 },
  { lng: -80.9110717773437, lat: 29.0333118438721 },
  { lng: -80.9115905761719, lat: 29.0330123901367 },
  { lng: -80.9120864868164, lat: 29.0326614379883 },
  { lng: -80.9126434326172, lat: 29.0323867797853 },
  { lng: -80.9132690429687, lat: 29.0321979522706 },
  { lng: -80.9138946533203, lat: 29.0319786071777 },
  { lng: -80.9145278930664, lat: 29.0318336486817 },
  { lng: -80.9151000976562, lat: 29.0315952301026 },
  { lng: -80.9155502319335, lat: 29.0312423706056 },
  { lng: -80.9159927368164, lat: 29.0308723449708 },
  { lng: -80.9164886474609, lat: 29.030559539795 },
  { lng: -80.9170227050781, lat: 29.0302810668946 },
  { lng: -80.9175872802734, lat: 29.0300540924073 },
  { lng: -80.9182128906249, lat: 29.0299606323242 },
  { lng: -80.9187774658203, lat: 29.0297622680664 },
  { lng: -80.9191513061523, lat: 29.0293807983398 },
  { lng: -80.9194183349609, lat: 29.0289306640626 },
  { lng: -80.9196853637695, lat: 29.0285091400148 },
  { lng: -80.919807434082, lat: 29.0281066894532 },
  { lng: -80.9194946289062, lat: 29.0277481079102 },
  { lng: -80.9192047119141, lat: 29.0273094177247 },
  { lng: -80.9190521240234, lat: 29.0267772674562 },
  { lng: -80.9191589355468, lat: 29.0261917114258 },
  { lng: -80.9195556640624, lat: 29.0256328582765 },
  { lng: -80.9197235107421, lat: 29.025417327881 },
  { lng: -80.9199752807617, lat: 29.025089263916 },
  { lng: -80.9200057983398, lat: 29.0244369506836 },
  { lng: -80.9195556640624, lat: 29.0238399505615 },
  { lng: -80.9192276000977, lat: 29.0236454010011 },
  { lng: -80.9188919067382, lat: 29.0233325958252 },
  { lng: -80.9188919067382, lat: 29.0230541229248 },
  { lng: -80.9186096191406, lat: 29.022777557373 },
  { lng: -80.9186096191406, lat: 29.0224990844727 },
  { lng: -80.9189376831055, lat: 29.0223217010499 },
  { lng: -80.9190979003906, lat: 29.0220737457276 },
  { lng: -80.9191436767578, lat: 29.0218162536621 },
  { lng: -80.9190444946289, lat: 29.021547317505 },
  { lng: -80.9188613891602, lat: 29.0212287902833 },
  { lng: -80.9185791015624, lat: 29.0209140777588 },
  { lng: -80.9184799194336, lat: 29.0205345153809 },
  { lng: -80.918228149414, lat: 29.0201892852784 },
  { lng: -80.9180145263671, lat: 29.0198459625245 },
  { lng: -80.9180374145507, lat: 29.0193386077882 },
  { lng: -80.9180374145507, lat: 29.0188255310059 },
  { lng: -80.9178237915038, lat: 29.0184020996094 },
  { lng: -80.9175338745117, lat: 29.0180282592774 },
  { lng: -80.9172210693359, lat: 29.0177783966064 },
  { lng: -80.9166641235352, lat: 29.0172195434571 },
  { lng: -80.9161148071288, lat: 29.0172195434571 },
  { lng: -80.9158325195312, lat: 29.016944885254 },
  { lng: -80.9152755737304, lat: 29.016944885254 },
  { lng: -80.9141693115234, lat: 29.0158329010011 },
  { lng: -80.9141693115234, lat: 29.0155563354493 },
  { lng: -80.9138870239258, lat: 29.0152797698975 },
  { lng: -80.9138870239258, lat: 29.0149993896486 },
  { lng: -80.913330078125, lat: 29.0144443511963 },
  { lng: -80.913330078125, lat: 29.0138874053956 },
  { lng: -80.912498474121, lat: 29.0130558013916 },
  { lng: -80.912498474121, lat: 29.0127773284912 },
  { lng: -80.9138259887695, lat: 29.0114517211914 },
  { lng: -80.9138870239258, lat: 29.0113887786865 },
  { lng: -80.9137268066406, lat: 29.0112247467042 },
  { lng: -80.9136657714844, lat: 29.0110893249513 },
  { lng: -80.913330078125, lat: 29.0111103057861 },
  { lng: -80.9130554199218, lat: 29.0108337402345 },
  { lng: -80.9127807617187, lat: 29.0108337402345 },
  { lng: -80.9122238159179, lat: 29.0102787017823 },
  { lng: -80.9122238159179, lat: 29.0100002288819 },
  { lng: -80.9119415283202, lat: 29.0097217559816 },
  { lng: -80.9119415283202, lat: 29.0091667175294 },
  { lng: -80.9122238159179, lat: 29.008888244629 },
  { lng: -80.9122238159179, lat: 29.0078048706054 },
  { lng: -80.9119415283202, lat: 29.0074996948243 },
  { lng: -80.9116668701172, lat: 29.0074996948243 },
  { lng: -80.9113922119141, lat: 29.0072231292725 },
  { lng: -80.9105529785156, lat: 29.0072231292725 },
  { lng: -80.9102783203125, lat: 29.0069446563721 },
  { lng: -80.9097213745117, lat: 29.0069446563721 },
  { lng: -80.9091644287109, lat: 29.0063896179199 },
  { lng: -80.9091644287109, lat: 29.0061111450195 },
  { lng: -80.9088897705077, lat: 29.0058345794679 },
  { lng: -80.9091644287109, lat: 29.0055561065673 },
  { lng: -80.9091644287109, lat: 29.0052776336669 },
  { lng: -80.9094467163085, lat: 29.0049991607666 },
  { lng: -80.9094467163085, lat: 29.004722595215 },
  { lng: -80.9088897705077, lat: 29.0041675567628 },
  { lng: -80.9088897705077, lat: 29.0038890838624 },
  { lng: -80.9086074829102, lat: 29.0030555725098 },
  { lng: -80.9086074829102, lat: 29.0024986267091 },
  { lng: -80.908332824707, lat: 29.0022201538087 },
  { lng: -80.908332824707, lat: 29.0011100769044 },
  { lng: -80.9080581665039, lat: 29.0008335113525 },
  { lng: -80.9077758789062, lat: 28.9999980926514 },
  { lng: -80.9069442749023, lat: 28.9980564117432 },
  { lng: -80.9066696166992, lat: 28.9977779388429 },
  { lng: -80.9066696166992, lat: 28.9966640472413 },
  { lng: -80.9038925170898, lat: 28.9938888549805 },
  { lng: -80.9036102294921, lat: 28.9938888549805 },
  { lng: -80.903335571289, lat: 28.9936103820801 },
  { lng: -80.903335571289, lat: 28.9933338165284 },
  { lng: -80.9038925170898, lat: 28.9927768707275 },
  { lng: -80.9038925170898, lat: 28.9922237396241 },
  { lng: -80.9036102294921, lat: 28.9919452667237 },
  { lng: -80.9036102294921, lat: 28.991388320923 },
  { lng: -80.903335571289, lat: 28.9911136627197 },
  { lng: -80.903335571289, lat: 28.9908332824708 },
  { lng: -80.9022216796875, lat: 28.9897193908693 },
  { lng: -80.9022216796875, lat: 28.9891662597656 },
  { lng: -80.9019470214844, lat: 28.9888916015626 },
  { lng: -80.9016647338867, lat: 28.9888916015626 },
  { lng: -80.9016647338867, lat: 28.9883327484131 },
  { lng: -80.9011077880859, lat: 28.9877777099609 },
  { lng: -80.9011077880859, lat: 28.9874973297119 },
  { lng: -80.9008331298828, lat: 28.9872226715089 },
  { lng: -80.9005584716796, lat: 28.9872226715089 },
  { lng: -80.899444580078, lat: 28.986110687256 },
  { lng: -80.899444580078, lat: 28.9858341217042 },
  { lng: -80.8980560302734, lat: 28.9844436645508 },
  { lng: -80.8980560302734, lat: 28.9838886260987 },
  { lng: -80.8977813720703, lat: 28.9836120605469 },
  { lng: -80.8977813720703, lat: 28.9833335876465 },
  { lng: -80.8974990844727, lat: 28.9830551147461 },
  { lng: -80.8974990844727, lat: 28.9827785491943 },
  { lng: -80.8961105346679, lat: 28.981388092041 },
  { lng: -80.8961105346679, lat: 28.9805564880372 },
  { lng: -80.8956146240234, lat: 28.9800643920899 },
  { lng: -80.8947219848633, lat: 28.9791660308838 },
  { lng: -80.8947219848633, lat: 28.9786128997803 },
  { lng: -80.8944473266602, lat: 28.9783306121827 },
  { lng: -80.8944473266602, lat: 28.9780559539795 },
  { lng: -80.8938903808593, lat: 28.9775028228761 },
  { lng: -80.8938903808593, lat: 28.977222442627 },
  { lng: -80.8936080932617, lat: 28.976942062378 },
  { lng: -80.8936080932617, lat: 28.9763908386232 },
  { lng: -80.8927764892578, lat: 28.975555419922 },
  { lng: -80.8927764892578, lat: 28.9752769470214 },
  { lng: -80.8925018310547, lat: 28.974998474121 },
  { lng: -80.8925018310547, lat: 28.9747219085694 },
  { lng: -80.892219543457, lat: 28.9744453430176 },
  { lng: -80.892219543457, lat: 28.9741668701172 },
  { lng: -80.8919448852539, lat: 28.9738883972169 },
  { lng: -80.8919448852539, lat: 28.9727764129639 },
  { lng: -80.8916702270508, lat: 28.9724998474122 },
  { lng: -80.8916702270508, lat: 28.9722213745118 },
  { lng: -80.8905563354492, lat: 28.9711112976074 },
  { lng: -80.8905563354492, lat: 28.970832824707 },
  { lng: -80.8902740478515, lat: 28.9705562591552 },
  { lng: -80.8902740478515, lat: 28.9702777862548 },
  { lng: -80.8897247314453, lat: 28.9697227478028 },
  { lng: -80.8897247314453, lat: 28.9694442749025 },
  { lng: -80.8891677856445, lat: 28.9688892364503 },
  { lng: -80.8891677856445, lat: 28.9686107635499 },
  { lng: -80.8883361816406, lat: 28.9677772521973 },
  { lng: -80.8883361816406, lat: 28.9675006866456 },
  { lng: -80.888053894043, lat: 28.9672222137452 },
  { lng: -80.888053894043, lat: 28.9669437408448 },
  { lng: -80.887222290039, lat: 28.9661102294922 },
  { lng: -80.887222290039, lat: 28.96555519104 },
  { lng: -80.8869476318359, lat: 28.9652786254883 },
  { lng: -80.8869476318359, lat: 28.9650001525879 },
  { lng: -80.8866653442382, lat: 28.9647235870362 },
  { lng: -80.8866653442382, lat: 28.9644451141359 },
  { lng: -80.8861083984375, lat: 28.9638881683349 },
  { lng: -80.8861083984375, lat: 28.9633312225342 },
  { lng: -80.8858337402344, lat: 28.9630546569825 },
  { lng: -80.8858337402344, lat: 28.9627780914308 },
  { lng: -80.8852767944336, lat: 28.9622230529786 },
  { lng: -80.8852767944336, lat: 28.9616661071778 },
  { lng: -80.8850021362305, lat: 28.9613914489746 },
  { lng: -80.8850021362305, lat: 28.9608325958253 },
  { lng: -80.8847198486328, lat: 28.9605560302734 },
  { lng: -80.8847198486328, lat: 28.960277557373 },
  { lng: -80.8844451904296, lat: 28.9599971771241 },
  { lng: -80.8844451904296, lat: 28.9597225189209 },
  { lng: -80.884162902832, lat: 28.9594440460205 },
  { lng: -80.884162902832, lat: 28.9591674804687 },
  { lng: -80.883056640625, lat: 28.9580554962159 },
  { lng: -80.883056640625, lat: 28.9577751159668 },
  { lng: -80.8827743530273, lat: 28.9575004577638 },
  { lng: -80.8827743530273, lat: 28.956943511963 },
  { lng: -80.8805541992187, lat: 28.9547214508057 },
  { lng: -80.8805541992187, lat: 28.9544448852539 },
  { lng: -80.8797225952148, lat: 28.9536113739014 },
  { lng: -80.8794479370117, lat: 28.9536113739014 },
  { lng: -80.8777770996093, lat: 28.9519443511964 },
  { lng: -80.8775024414062, lat: 28.9519443511964 },
  { lng: -80.8772201538085, lat: 28.951665878296 },
  { lng: -80.8772201538085, lat: 28.9513893127443 },
  { lng: -80.8766632080078, lat: 28.950834274292 },
  { lng: -80.8766632080078, lat: 28.9505558013917 },
  { lng: -80.8747253417968, lat: 28.9486083984376 },
  { lng: -80.8747253417968, lat: 28.9483337402344 },
  { lng: -80.8744430541992, lat: 28.948055267334 },
  { lng: -80.8744430541992, lat: 28.947780609131 },
  { lng: -80.8738861083983, lat: 28.9472217559814 },
  { lng: -80.8738861083983, lat: 28.9469451904298 },
  { lng: -80.8733367919922, lat: 28.9463882446288 },
  { lng: -80.8733367919922, lat: 28.9461116790773 },
  { lng: -80.8730545043945, lat: 28.9458332061769 },
  { lng: -80.8730545043945, lat: 28.9452762603761 },
  { lng: -80.8722229003906, lat: 28.9444446563721 },
  { lng: -80.8722229003906, lat: 28.9436111450196 },
  { lng: -80.8719482421874, lat: 28.9433326721192 },
  { lng: -80.8719482421874, lat: 28.9430541992188 },
  { lng: -80.8716659545898, lat: 28.942777633667 },
  { lng: -80.8716659545898, lat: 28.9416675567626 },
  { lng: -80.8713912963867, lat: 28.9413890838623 },
  { lng: -80.871109008789, lat: 28.9413890838623 },
  { lng: -80.8708343505858, lat: 28.9416675567626 },
  { lng: -80.8705520629882, lat: 28.9416675567626 },
  { lng: -80.8702774047852, lat: 28.9413890838623 },
  { lng: -80.870002746582, lat: 28.9413890838623 },
  { lng: -80.8688888549805, lat: 28.9402770996093 },
  { lng: -80.8688888549805, lat: 28.9400005340577 },
  { lng: -80.8686141967773, lat: 28.9397220611573 },
  { lng: -80.8686141967773, lat: 28.9388885498048 },
  { lng: -80.8683319091796, lat: 28.9386138916016 },
  { lng: -80.8683319091796, lat: 28.9380550384522 },
  { lng: -80.8680572509765, lat: 28.9377784729004 },
  { lng: -80.8680572509765, lat: 28.9366664886475 },
  { lng: -80.8677749633788, lat: 28.9363899230957 },
  { lng: -80.8683319091796, lat: 28.9358310699463 },
  { lng: -80.8686141967773, lat: 28.9358310699463 },
  { lng: -80.8691635131836, lat: 28.9352798461915 },
  { lng: -80.8691635131836, lat: 28.9350013732911 },
  { lng: -80.8688888549805, lat: 28.9347229003907 },
  { lng: -80.8686141967773, lat: 28.9347229003907 },
  { lng: -80.8683319091796, lat: 28.9344444274903 },
  { lng: -80.8680572509765, lat: 28.9344444274903 },
  { lng: -80.866943359375, lat: 28.9333324432374 },
  { lng: -80.866943359375, lat: 28.9313888549805 },
  { lng: -80.8663864135742, lat: 28.9308338165283 },
  { lng: -80.8663864135742, lat: 28.9305553436279 },
  { lng: -80.865837097168, lat: 28.9300003051758 },
  { lng: -80.865837097168, lat: 28.9297218322754 },
  { lng: -80.8655548095703, lat: 28.9294452667237 },
  { lng: -80.8655548095703, lat: 28.9288883209228 },
  { lng: -80.8652801513671, lat: 28.9286117553712 },
  { lng: -80.8652801513671, lat: 28.9283332824708 },
  { lng: -80.8647232055663, lat: 28.9277782440187 },
  { lng: -80.8647232055663, lat: 28.9272212982179 },
  { lng: -80.8644409179687, lat: 28.9269447326661 },
  { lng: -80.8644409179687, lat: 28.9263896942139 },
  { lng: -80.8641662597656, lat: 28.9261112213135 },
  { lng: -80.8641662597656, lat: 28.9255561828613 },
  { lng: -80.8638916015625, lat: 28.9252777099609 },
  { lng: -80.8638916015625, lat: 28.9247226715088 },
  { lng: -80.8636093139648, lat: 28.9244441986084 },
  { lng: -80.8636093139648, lat: 28.924165725708 },
  { lng: -80.8633346557617, lat: 28.9238891601562 },
  { lng: -80.8633346557617, lat: 28.9224987030029 },
  { lng: -80.8636093139648, lat: 28.9222202301025 },
  { lng: -80.8633346557617, lat: 28.9219436645509 },
  { lng: -80.8633346557617, lat: 28.9216670989991 },
  { lng: -80.8630523681641, lat: 28.9213905334473 },
  { lng: -80.8630523681641, lat: 28.9208335876465 },
  { lng: -80.8622207641601, lat: 28.9199981689454 },
  { lng: -80.8622207641601, lat: 28.9194450378418 },
  { lng: -80.861946105957, lat: 28.9191665649414 },
  { lng: -80.861946105957, lat: 28.9188861846924 },
  { lng: -80.8613891601562, lat: 28.9183330535889 },
  { lng: -80.8613891601562, lat: 28.9180564880371 },
  { lng: -80.8611145019531, lat: 28.9177761077881 },
  { lng: -80.8611145019531, lat: 28.9172229766847 },
  { lng: -80.8608322143555, lat: 28.9169445037843 },
  { lng: -80.8608322143555, lat: 28.9158325195314 },
  { lng: -80.8605575561523, lat: 28.915554046631 },
  { lng: -80.8605575561523, lat: 28.9152774810792 },
  { lng: -80.8602752685547, lat: 28.9150009155274 },
  { lng: -80.8602752685547, lat: 28.9141674041748 },
  { lng: -80.8591690063476, lat: 28.9130554199219 },
  { lng: -80.8591690063476, lat: 28.9111137390137 },
  { lng: -80.858055114746, lat: 28.9100017547608 },
  { lng: -80.8583297729492, lat: 28.909719467163 },
  { lng: -80.8583297729492, lat: 28.9091663360596 },
  { lng: -80.8561096191406, lat: 28.9069442749023 },
  { lng: -80.8561096191406, lat: 28.9063873291016 },
  { lng: -80.8555526733398, lat: 28.9058341979982 },
  { lng: -80.8555526733398, lat: 28.9050006866456 },
  { lng: -80.8552780151367, lat: 28.9047222137452 },
  { lng: -80.8552780151367, lat: 28.9041652679444 },
  { lng: -80.8544464111328, lat: 28.9033336639405 },
  { lng: -80.8541641235352, lat: 28.9033336639405 },
  { lng: -80.853889465332, lat: 28.9030551910401 },
  { lng: -80.853889465332, lat: 28.9027786254883 },
  { lng: -80.8536148071289, lat: 28.9025001525879 },
  { lng: -80.8536148071289, lat: 28.9016666412354 },
  { lng: -80.8530578613281, lat: 28.9011116027832 },
  { lng: -80.8530578613281, lat: 28.9008331298828 },
  { lng: -80.8527755737304, lat: 28.9005546569824 },
  { lng: -80.8527755737304, lat: 28.8999996185303 },
  { lng: -80.8525009155273, lat: 28.8997230529786 },
  { lng: -80.8525009155273, lat: 28.8988895416261 },
  { lng: -80.8516693115233, lat: 28.8980560302735 },
  { lng: -80.8516693115233, lat: 28.8969440460206 },
  { lng: -80.8499984741211, lat: 28.8952770233154 },
  { lng: -80.8499984741211, lat: 28.894998550415 },
  { lng: -80.8494415283203, lat: 28.8944435119629 },
  { lng: -80.8494415283203, lat: 28.8924999237062 },
  { lng: -80.848892211914, lat: 28.891944885254 },
  { lng: -80.848892211914, lat: 28.8916664123536 },
  { lng: -80.8475036621094, lat: 28.8902778625488 },
  { lng: -80.8475036621094, lat: 28.8899993896485 },
  { lng: -80.8469467163086, lat: 28.8894443511963 },
  { lng: -80.8469467163086, lat: 28.8883342742921 },
  { lng: -80.8461074829101, lat: 28.8875007629396 },
  { lng: -80.8461074829101, lat: 28.8861103057862 },
  { lng: -80.845832824707, lat: 28.8858337402344 },
  { lng: -80.845832824707, lat: 28.885555267334 },
  { lng: -80.8447189331055, lat: 28.8844451904297 },
  { lng: -80.8447189331055, lat: 28.8838882446289 },
  { lng: -80.8444442749023, lat: 28.8836116790771 },
  { lng: -80.8444442749023, lat: 28.882501602173 },
  { lng: -80.8433303833008, lat: 28.8813915252686 },
  { lng: -80.8433303833008, lat: 28.8811092376708 },
  { lng: -80.8427810668945, lat: 28.8805561065674 },
  { lng: -80.8424987792968, lat: 28.8805561065674 },
  { lng: -80.8422241210936, lat: 28.880277633667 },
  { lng: -80.8411102294922, lat: 28.880277633667 },
  { lng: -80.8397216796874, lat: 28.8788871765137 },
  { lng: -80.8397216796874, lat: 28.8783340454102 },
  { lng: -80.8402786254883, lat: 28.8777751922608 },
  { lng: -80.8402786254883, lat: 28.8775005340576 },
  { lng: -80.8411102294922, lat: 28.8766651153564 },
  { lng: -80.8408355712891, lat: 28.8763885498046 },
  { lng: -80.8408355712891, lat: 28.8755569458008 },
  { lng: -80.8391647338867, lat: 28.8738880157471 },
  { lng: -80.8391647338867, lat: 28.8736114501954 },
  { lng: -80.8388900756835, lat: 28.8733348846436 },
  { lng: -80.8388900756835, lat: 28.8719444274902 },
  { lng: -80.8386077880859, lat: 28.8716659545898 },
  { lng: -80.8383331298828, lat: 28.8716659545898 },
  { lng: -80.8380584716797, lat: 28.8713893890381 },
  { lng: -80.8375015258789, lat: 28.8713893890381 },
  { lng: -80.8366699218749, lat: 28.8705558776857 },
  { lng: -80.8366699218749, lat: 28.8702774047851 },
  { lng: -80.8369445800781, lat: 28.8700027465821 },
  { lng: -80.8369445800781, lat: 28.8694438934327 },
  { lng: -80.8349990844727, lat: 28.8658332824707 },
  { lng: -80.8333358764648, lat: 28.8666667938232 },
  { lng: -80.8330535888671, lat: 28.8663883209229 },
  { lng: -80.8324966430663, lat: 28.8669452667236 },
  { lng: -80.8313903808594, lat: 28.8669452667236 },
  { lng: -80.8305587768554, lat: 28.8661117553711 },
  { lng: -80.8308334350586, lat: 28.8658332824707 },
  { lng: -80.8308334350586, lat: 28.8655548095703 },
  { lng: -80.8311080932617, lat: 28.8652763366699 },
  { lng: -80.8319473266602, lat: 28.8652763366699 },
  { lng: -80.8322219848633, lat: 28.8649997711182 },
  { lng: -80.8324966430663, lat: 28.8649997711182 },
  { lng: -80.8330535888671, lat: 28.8644447326661 },
  { lng: -80.8336105346679, lat: 28.8644447326661 },
  { lng: -80.8336105346679, lat: 28.863889694214 },
  { lng: -80.832778930664, lat: 28.8630542755128 },
  { lng: -80.8330535888671, lat: 28.862777709961 },
  { lng: -80.8330535888671, lat: 28.8616657257081 },
  { lng: -80.8319473266602, lat: 28.8605556488037 },
  { lng: -80.8319473266602, lat: 28.8602771759033 },
  { lng: -80.8316650390625, lat: 28.8600006103516 },
  { lng: -80.8316650390625, lat: 28.8594436645508 },
  { lng: -80.8308334350586, lat: 28.858612060547 },
  { lng: -80.8308334350586, lat: 28.8583335876466 },
  { lng: -80.8305587768554, lat: 28.8583335876466 },
  { lng: -80.8300018310546, lat: 28.8577785491944 },
  { lng: -80.8300018310546, lat: 28.8575000762941 },
  { lng: -80.8302764892578, lat: 28.8572196960449 },
  { lng: -80.8302764892578, lat: 28.8563880920411 },
  { lng: -80.8300018310546, lat: 28.8561115264893 },
  { lng: -80.8300018310546, lat: 28.8558311462403 },
  { lng: -80.829719543457, lat: 28.8555564880371 },
  { lng: -80.8294448852538, lat: 28.8555564880371 },
  { lng: -80.8294448852538, lat: 28.8552799224854 },
  { lng: -80.8283309936523, lat: 28.8541660308838 },
  { lng: -80.8283309936523, lat: 28.853609085083 },
  { lng: -80.8280563354492, lat: 28.8533325195312 },
  { lng: -80.8280563354492, lat: 28.852777481079 },
  { lng: -80.8277740478516, lat: 28.8525009155275 },
  { lng: -80.8277740478516, lat: 28.8522224426271 },
  { lng: -80.8274993896484, lat: 28.8519439697267 },
  { lng: -80.8272247314453, lat: 28.8516693115235 },
  { lng: -80.8269424438476, lat: 28.8516693115235 },
  { lng: -80.8266677856445, lat: 28.8513889312745 },
  { lng: -80.8263854980468, lat: 28.8513889312745 },
  { lng: -80.8263854980468, lat: 28.8505554199219 },
  { lng: -80.8266677856445, lat: 28.8502769470215 },
  { lng: -80.8263854980468, lat: 28.8500003814698 },
  { lng: -80.8263854980468, lat: 28.8494453430176 },
  { lng: -80.8261108398436, lat: 28.8491668701172 },
  { lng: -80.8261108398436, lat: 28.848611831665 },
  { lng: -80.8258361816406, lat: 28.8483333587646 },
  { lng: -80.8258361816406, lat: 28.8480548858643 },
  { lng: -80.825553894043, lat: 28.8477783203124 },
  { lng: -80.825553894043, lat: 28.8474998474121 },
  { lng: -80.8252792358398, lat: 28.8472213745117 },
  { lng: -80.8249969482422, lat: 28.8472213745117 },
  { lng: -80.8247222900391, lat: 28.8469448089601 },
  { lng: -80.8244476318359, lat: 28.8469448089601 },
  { lng: -80.8241653442382, lat: 28.8466663360597 },
  { lng: -80.8241653442382, lat: 28.8458347320557 },
  { lng: -80.8238906860351, lat: 28.8455562591553 },
  { lng: -80.8238906860351, lat: 28.8449993133546 },
  { lng: -80.8233337402343, lat: 28.8444442749024 },
  { lng: -80.8233337402343, lat: 28.844165802002 },
  { lng: -80.8225021362305, lat: 28.843334197998 },
  { lng: -80.8225021362305, lat: 28.8430557250977 },
  { lng: -80.8222198486328, lat: 28.8427772521973 },
  { lng: -80.8222198486328, lat: 28.8425025939943 },
  { lng: -80.8219451904297, lat: 28.8422203063965 },
  { lng: -80.8219451904297, lat: 28.8419437408447 },
  { lng: -80.821662902832, lat: 28.8416671752929 },
  { lng: -80.821662902832, lat: 28.8411083221436 },
  { lng: -80.8202743530273, lat: 28.8397216796876 },
  { lng: -80.8202743530273, lat: 28.8394451141358 },
  { lng: -80.8199996948242, lat: 28.8391666412354 },
  { lng: -80.8199996948242, lat: 28.8388862609864 },
  { lng: -80.8202743530273, lat: 28.8386116027833 },
  { lng: -80.8202743530273, lat: 28.8383331298829 },
  { lng: -80.8188858032227, lat: 28.8369445800781 },
  { lng: -80.8188858032227, lat: 28.8366661071777 },
  { lng: -80.8186111450195, lat: 28.836389541626 },
  { lng: -80.8186111450195, lat: 28.8361110687256 },
  { lng: -80.8183364868164, lat: 28.8358325958252 },
  { lng: -80.8183364868164, lat: 28.8355541229248 },
  { lng: -80.8172225952148, lat: 28.8344459533692 },
  { lng: -80.8172225952148, lat: 28.8341674804688 },
  { lng: -80.8169479370117, lat: 28.8338890075685 },
  { lng: -80.8169479370117, lat: 28.8333320617677 },
  { lng: -80.8166656494139, lat: 28.8330554962159 },
  { lng: -80.8166656494139, lat: 28.8327770233155 },
  { lng: -80.8152770996094, lat: 28.8313884735107 },
  { lng: -80.8152770996094, lat: 28.8311138153077 },
  { lng: -80.8136138916014, lat: 28.829444885254 },
  { lng: -80.8136138916014, lat: 28.8291664123536 },
  { lng: -80.8122253417969, lat: 28.8277797698975 },
  { lng: -80.8122253417969, lat: 28.8274974822999 },
  { lng: -80.8111114501953, lat: 28.8263874053956 },
  { lng: -80.8111114501953, lat: 28.8250007629395 },
  { lng: -80.8108367919921, lat: 28.8247222900391 },
  { lng: -80.8108367919921, lat: 28.8244438171387 },
  { lng: -80.8105545043945, lat: 28.8241653442383 },
  { lng: -80.8105545043945, lat: 28.8238887786865 },
  { lng: -80.8099975585938, lat: 28.8233337402345 },
  { lng: -80.8097229003906, lat: 28.8233337402345 },
  { lng: -80.8094482421875, lat: 28.8230552673341 },
  { lng: -80.8091659545898, lat: 28.8230552673341 },
  { lng: -80.8077774047851, lat: 28.8216667175294 },
  { lng: -80.8077774047851, lat: 28.821388244629 },
  { lng: -80.8072204589843, lat: 28.8208332061768 },
  { lng: -80.8072204589843, lat: 28.8205547332764 },
  { lng: -80.8063888549805, lat: 28.8197231292725 },
  { lng: -80.8063888549805, lat: 28.8194446563721 },
  { lng: -80.8061141967773, lat: 28.8191661834717 },
  { lng: -80.8061141967773, lat: 28.8186111450195 },
  { lng: -80.8055572509766, lat: 28.8180561065675 },
  { lng: -80.8055572509766, lat: 28.817222595215 },
  { lng: -80.8047256469726, lat: 28.8163890838624 },
  { lng: -80.8047256469726, lat: 28.8161125183106 },
  { lng: -80.8041687011718, lat: 28.8155555725098 },
  { lng: -80.8041687011718, lat: 28.8152770996094 },
  { lng: -80.803337097168, lat: 28.8144435882569 },
  { lng: -80.803337097168, lat: 28.8141689300537 },
  { lng: -80.8027801513672, lat: 28.8136100769044 },
  { lng: -80.8027801513672, lat: 28.8133335113525 },
  { lng: -80.8024978637695, lat: 28.8130550384521 },
  { lng: -80.8024978637695, lat: 28.8127803802491 },
  { lng: -80.8011093139648, lat: 28.8113861083984 },
  { lng: -80.8011093139648, lat: 28.8111114501954 },
  { lng: -80.7997207641602, lat: 28.8097229003907 },
  { lng: -80.7997207641602, lat: 28.8094444274903 },
  { lng: -80.799446105957, lat: 28.8091640472413 },
  { lng: -80.799446105957, lat: 28.8088893890381 },
  { lng: -80.7988891601562, lat: 28.8083324432374 },
  { lng: -80.7988891601562, lat: 28.8080558776855 },
  { lng: -80.7983322143554, lat: 28.8075008392334 },
  { lng: -80.7983322143554, lat: 28.807222366333 },
  { lng: -80.7975006103514, lat: 28.8063888549804 },
  { lng: -80.7975006103514, lat: 28.8061103820801 },
  { lng: -80.7972259521484, lat: 28.8058338165284 },
  { lng: -80.7972259521484, lat: 28.805555343628 },
  { lng: -80.7969436645508, lat: 28.8052768707277 },
  { lng: -80.7969436645508, lat: 28.8050003051759 },
  { lng: -80.7966690063477, lat: 28.8047237396241 },
  { lng: -80.7966690063477, lat: 28.8044452667237 },
  { lng: -80.7961120605469, lat: 28.8038883209229 },
  { lng: -80.7961120605469, lat: 28.8036117553712 },
  { lng: -80.795555114746, lat: 28.8030548095704 },
  { lng: -80.795555114746, lat: 28.8027782440186 },
  { lng: -80.7944412231445, lat: 28.8016662597656 },
  { lng: -80.7944412231445, lat: 28.8011093139648 },
  { lng: -80.7941665649414, lat: 28.8008327484131 },
  { lng: -80.7941665649414, lat: 28.8005561828614 },
  { lng: -80.7938919067383, lat: 28.8002796173097 },
  { lng: -80.7938919067383, lat: 28.7999973297119 },
  { lng: -80.7933349609375, lat: 28.7994441986085 },
  { lng: -80.7933349609375, lat: 28.7991657257081 },
  { lng: -80.7927780151367, lat: 28.798610687256 },
  { lng: -80.7927780151367, lat: 28.7983341217042 },
  { lng: -80.7925033569335, lat: 28.7980556488038 },
  { lng: -80.7925033569335, lat: 28.7977752685548 },
  { lng: -80.7919464111328, lat: 28.7972221374512 },
  { lng: -80.7919464111328, lat: 28.7969436645508 },
  { lng: -80.7916641235351, lat: 28.7966651916504 },
  { lng: -80.7916641235351, lat: 28.7963886260986 },
  { lng: -80.7911148071289, lat: 28.7958335876465 },
  { lng: -80.7911148071289, lat: 28.7955551147461 },
  { lng: -80.7905578613281, lat: 28.7950000762939 },
  { lng: -80.7905578613281, lat: 28.7947216033936 },
  { lng: -80.7900009155273, lat: 28.7941665649415 },
  { lng: -80.7900009155273, lat: 28.793888092041 },
  { lng: -80.7891693115234, lat: 28.7930564880372 },
  { lng: -80.7891693115234, lat: 28.7927780151368 },
  { lng: -80.7888870239257, lat: 28.7924995422364 },
  { lng: -80.7888870239257, lat: 28.7922248840332 },
  { lng: -80.7886123657226, lat: 28.7919445037842 },
  { lng: -80.7886123657226, lat: 28.7916660308838 },
  { lng: -80.7884368896483, lat: 28.7914886474611 },
  { lng: -80.7889022827148, lat: 28.7914981842042 },
  { lng: -80.7972259521484, lat: 28.7912902832032 },
  { lng: -80.8159332275391, lat: 28.7913894653321 },
  { lng: -80.8291702270508, lat: 28.7914257049562 },
  { lng: -80.8297653198242, lat: 28.7914371490479 },
  { lng: -80.8300018310546, lat: 28.7916660308838 },
  { lng: -80.8308334350586, lat: 28.7916660308838 },
  { lng: -80.8311080932617, lat: 28.7919445037842 },
  { lng: -80.8313903808594, lat: 28.7919445037842 },
  { lng: -80.8316650390625, lat: 28.7922248840332 },
  { lng: -80.8319473266602, lat: 28.7922248840332 },
  { lng: -80.8322219848633, lat: 28.7924995422364 },
  { lng: -80.8322219848633, lat: 28.7927780151368 },
  { lng: -80.8333358764648, lat: 28.793888092041 },
  { lng: -80.8333358764648, lat: 28.7941665649415 },
  { lng: -80.8338851928711, lat: 28.7947216033936 },
  { lng: -80.8338851928711, lat: 28.7950000762939 },
  { lng: -80.8347244262695, lat: 28.7958335876465 },
  { lng: -80.8347244262695, lat: 28.7961120605469 },
  { lng: -80.8352813720703, lat: 28.7966651916504 },
  { lng: -80.8355560302734, lat: 28.7966651916504 },
  { lng: -80.8358306884765, lat: 28.7969436645508 },
  { lng: -80.8361129760742, lat: 28.7966651916504 },
  { lng: -80.8363876342773, lat: 28.7966651916504 },
  { lng: -80.8366699218749, lat: 28.7969436645508 },
  { lng: -80.8372192382812, lat: 28.7969436645508 },
  { lng: -80.8375015258789, lat: 28.7966651916504 },
  { lng: -80.8383331298828, lat: 28.7966651916504 },
  { lng: -80.8394470214843, lat: 28.7972221374512 },
  { lng: -80.8399963378906, lat: 28.7972221374512 },
  { lng: -80.8402786254883, lat: 28.7975006103516 },
  { lng: -80.8406295776367, lat: 28.7975006103516 },
  { lng: -80.8408355712891, lat: 28.7975006103516 },
  { lng: -80.8411102294922, lat: 28.7978420257569 },
  { lng: -80.8411102294922, lat: 28.7983341217042 },
  { lng: -80.841941833496, lat: 28.7991657257081 },
  { lng: -80.8424987792968, lat: 28.7991657257081 },
  { lng: -80.8427810668945, lat: 28.7994441986085 },
  { lng: -80.843376159668, lat: 28.7994441986085 },
  { lng: -80.8436126708984, lat: 28.7994441986085 },
  { lng: -80.8438491821288, lat: 28.7997798919678 },
  { lng: -80.8442001342773, lat: 28.8000297546387 },
  { lng: -80.8477630615234, lat: 28.8019695281982 },
  { lng: -80.8528900146484, lat: 28.8039627075195 },
  { lng: -80.857048034668, lat: 28.8040790557862 },
  { lng: -80.8587417602539, lat: 28.8004608154298 },
  { lng: -80.8562927246094, lat: 28.7967128753663 },
  { lng: -80.8533401489258, lat: 28.7920303344728 },
  { lng: -80.8528518676758, lat: 28.79109954834 },
  { lng: -80.8570098876952, lat: 28.7912197113038 },
  { lng: -80.8830032348633, lat: 28.7910594940187 },
  { lng: -80.9677429199219, lat: 28.7902717590331 },
  { lng: -80.9671096801758, lat: 28.750280380249 },
  { lng: -80.9640274047852, lat: 28.6054725646973 },
  { lng: -80.9680862426757, lat: 28.6078815460205 },
  { lng: -80.9721527099609, lat: 28.6102905273439 },
  { lng: -80.9742431640625, lat: 28.6098918914795 },
  { lng: -80.9753417968749, lat: 28.6080799102783 },
  { lng: -80.9779205322265, lat: 28.6086196899414 },
  { lng: -80.984016418457, lat: 28.6120109558106 },
  { lng: -80.9876174926758, lat: 28.6130294799805 },
  { lng: -80.989990234375, lat: 28.619068145752 },
  { lng: -80.9955368041992, lat: 28.6233615875244 },
  { lng: -81.0015258789062, lat: 28.6299610137941 },
  { lng: -81.0070266723633, lat: 28.6356296539307 },
  { lng: -81.0061721801758, lat: 28.6452484130859 },
  { lng: -81.0134658813477, lat: 28.6587810516358 },
  { lng: -81.0189208984374, lat: 28.6662807464601 },
  { lng: -81.0211563110352, lat: 28.6764507293701 },
  { lng: -81.020866394043, lat: 28.6842594146729 },
  { lng: -81.0165023803711, lat: 28.6905708312988 },
  { lng: -81.0184173583984, lat: 28.6947612762451 },
  { lng: -81.022850036621, lat: 28.7017707824706 },
  { lng: -81.0307769775391, lat: 28.7125606536865 },
  { lng: -81.0342330932617, lat: 28.717700958252 },
  { lng: -81.0377502441406, lat: 28.7210197448732 },
  { lng: -81.0416717529297, lat: 28.7275600433349 },
  { lng: -81.0420608520508, lat: 28.7312412261964 },
  { lng: -81.05322265625, lat: 28.7389011383058 },
  { lng: -81.0611190795898, lat: 28.7506008148194 },
  { lng: -81.0537567138672, lat: 28.7825508117676 },
  { lng: -81.0590896606444, lat: 28.7937183380127 },
  { lng: -81.0670928955078, lat: 28.8026695251464 },
  { lng: -81.0668640136719, lat: 28.8095512390137 },
  { lng: -81.0713729858398, lat: 28.8142700195312 },
  { lng: -81.076431274414, lat: 28.8180809020997 },
  { lng: -81.0811767578125, lat: 28.8159103393556 },
  { lng: -81.0913391113281, lat: 28.8230781555175 },
  { lng: -81.0991058349609, lat: 28.8237495422364 },
  { lng: -81.1072616577148, lat: 28.8285598754883 },
  { lng: -81.1182861328124, lat: 28.8256416320801 },
  { lng: -81.1246566772461, lat: 28.8216800689698 },
  { lng: -81.1238784790039, lat: 28.8143100738525 },
  { lng: -81.1287460327148, lat: 28.8084697723389 },
  { lng: -81.1300582885742, lat: 28.8006992340089 },
  { lng: -81.136962890625, lat: 28.7967491149902 },
  { lng: -81.1428375244141, lat: 28.7918491363526 },
  { lng: -81.1547775268555, lat: 28.7921695709229 },
  { lng: -81.164093017578, lat: 28.7933311462402 },
  { lng: -81.1718368530273, lat: 28.7949104309082 },
  { lng: -81.1816406249999, lat: 28.7970104217529 },
  { lng: -81.1874008178711, lat: 28.7957801818849 },
  { lng: -81.1930923461914, lat: 28.7963905334473 },
  { lng: -81.2003173828124, lat: 28.7979526519776 },
  { lng: -81.2094573974609, lat: 28.8041610717773 },
  { lng: -81.2134628295898, lat: 28.8093204498291 },
  { lng: -81.2127532958984, lat: 28.8148097991944 },
  { lng: -81.2167358398436, lat: 28.8199691772461 },
  { lng: -81.2222671508788, lat: 28.8256206512451 },
  { lng: -81.2267227172851, lat: 28.832618713379 },
  { lng: -81.3207778930663, lat: 28.8340911865234 },
  { lng: -81.3243026733398, lat: 28.8378505706787 },
  { lng: -81.3325424194336, lat: 28.8403491973878 },
  { lng: -81.3396530151367, lat: 28.8455810546876 },
  { lng: -81.3484573364258, lat: 28.8467082977295 },
  { lng: -81.359275817871, lat: 28.849739074707 },
  { lng: -81.3555068969727, lat: 28.8537807464601 },
  { lng: -81.3532562255859, lat: 28.8592319488527 },
  { lng: -81.3541870117188, lat: 28.8629302978516 },
  { lng: -81.3634872436523, lat: 28.8645305633545 },
  { lng: -81.363899230957, lat: 28.8682212829591 },
  { lng: -81.3617095947266, lat: 28.8718395233155 },
  { lng: -81.367202758789, lat: 28.8793201446533 },
  { lng: -81.3659820556639, lat: 28.8847999572754 },
  { lng: -81.3642959594727, lat: 28.8884296417237 },
  { lng: -81.3596115112305, lat: 28.8887805938722 },
  { lng: -81.3559570312499, lat: 28.8891410827637 },
  { lng: -81.3556976318359, lat: 28.8973999023438 },
  { lng: -81.358039855957, lat: 28.905731201172 },
  { lng: -81.3583297729492, lat: 28.9130802154541 },
  { lng: -81.3550033569335, lat: 28.9194202423096 },
  { lng: -81.3527679443359, lat: 28.9244194030762 },
  { lng: -81.3535232543945, lat: 28.9336185455323 },
  { lng: -81.3548431396484, lat: 28.9409980773926 },
  { lng: -81.3566360473632, lat: 28.9502201080323 },
  { lng: -81.3606262207031, lat: 28.9558296203613 },
  { lng: -81.365837097168, lat: 28.9554996490479 },
  { lng: -81.3661880493164, lat: 28.9610195159912 },
  { lng: -81.367546081543, lat: 28.9674816131593 },
  { lng: -81.3642959594727, lat: 28.9715309143066 },
  { lng: -81.3594131469727, lat: 28.9778308868409 },
  { lng: -81.3555831909179, lat: 28.9837093353273 },
  { lng: -81.3580169677734, lat: 28.9888191223144 },
  { lng: -81.3656997680663, lat: 28.9931411743164 },
  { lng: -81.3729858398438, lat: 28.9933204650879 },
  { lng: -81.3760528564453, lat: 28.9952297210693 },
  { lng: -81.3794403076172, lat: 29.0035705566407 },
  { lng: -81.3824005126953, lat: 29.0086898803712 },
  { lng: -81.3869705200195, lat: 29.0124797821046 },
  { lng: -81.3929367065429, lat: 29.0218009948732 },
  { lng: -81.394889831543, lat: 29.0259780883789 },
  { lng: -81.3994827270508, lat: 29.0293006896973 },
  { lng: -81.4018859863281, lat: 29.0353298187256 },
  { lng: -81.4096069335938, lat: 29.0387306213379 },
  { lng: -81.41845703125, lat: 29.0389404296875 },
  { lng: -81.4230194091797, lat: 29.0431804656984 },
  { lng: -81.4307327270507, lat: 29.0465793609619 },
  { lng: -81.4367294311523, lat: 29.0549812316896 },
  { lng: -81.4453964233398, lat: 29.0611515045167 },
  { lng: -81.453987121582, lat: 29.0705299377442 },
  { lng: -81.4547424316406, lat: 29.0801906585694 },
  { lng: -81.4524459838867, lat: 29.0870208740234 },
  { lng: -81.456901550293, lat: 29.0949306488037 },
  { lng: -81.4667358398438, lat: 29.0969905853273 },
  { lng: -81.4803619384766, lat: 29.095012664795 },
  { lng: -81.4844970703124, lat: 29.0960311889649 },
  { lng: -81.4938201904297, lat: 29.0985412597657 },
  { lng: -81.4948501586914, lat: 29.0990219116211 },
  { lng: -81.497428894043, lat: 29.1000003814697 },
  { lng: -81.4983673095703, lat: 29.1032428741455 },
  { lng: -81.5017700195312, lat: 29.1115703582764 },
  { lng: -81.5029525756836, lat: 29.1244506835938 },
  { lng: -81.5093460083008, lat: 29.1374511718751 },
  { lng: -81.5137557983398, lat: 29.147180557251 },
  { lng: -81.5202178955078, lat: 29.1578903198243 },
  { lng: -81.5239715576171, lat: 29.1722011566162 },
  { lng: -81.5301208496094, lat: 29.1760101318361 },
  { lng: -81.533576965332, lat: 29.1825103759765 },
  { lng: -81.5449600219726, lat: 29.1864395141602 },
  { lng: -81.5448608398437, lat: 29.1870498657228 },
  { lng: -81.5588073730468, lat: 29.1981220245362 },
  { lng: -81.581314086914, lat: 29.2215690612793 },
  { lng: -81.6413345336914, lat: 29.3281326293946 },
  { lng: -81.6413345336914, lat: 29.3299789428711 },
  { lng: -81.5712890624999, lat: 29.3485546112062 },
  { lng: -81.5714721679687, lat: 29.3488292694092 },
  { lng: -81.5712890624999, lat: 29.3488788604736 },
  { lng: -81.5435028076172, lat: 29.3554859161378 },
  { lng: -81.5410003662109, lat: 29.3560695648193 },
  { lng: -81.5183258056641, lat: 29.3619804382325 },
  { lng: -81.4524307250977, lat: 29.3783397674562 },
  { lng: -81.4460906982421, lat: 29.3804798126221 },
  { lng: -81.4407043457031, lat: 29.3854007720948 },
  { lng: -81.4373092651367, lat: 29.3935699462891 },
  { lng: -81.4340133666992, lat: 29.3985404968262 },
  { lng: -81.4308929443359, lat: 29.3980083465576 },
  { lng: -81.425163269043, lat: 29.397409439087 },
  { lng: -81.4208831787109, lat: 29.4000606536866 },
  { lng: -81.4208831787109, lat: 29.3835506439209 },
  { lng: -81.4173431396484, lat: 29.2609996795655 },
  { lng: -81.4089813232421, lat: 29.2607975006104 },
  { lng: -81.3833465576172, lat: 29.2615604400635 },
  { lng: -81.3749771118164, lat: 29.2618198394776 },
  { lng: -81.3462371826171, lat: 29.2620410919191 },
  { lng: -81.2270126342773, lat: 29.2640895843507 },
  { lng: -81.2244033813477, lat: 29.264030456543 },
  { lng: -81.2076568603515, lat: 29.2645111083986 },
  { lng: -81.1893310546874, lat: 29.2654209136963 },
  { lng: -81.1553573608398, lat: 29.2654495239259 },
  { lng: -81.1501159667969, lat: 29.2657680511476 },
  { lng: -81.1506729125977, lat: 29.280460357666 },
  { lng: -81.1532287597656, lat: 29.3585128784181 },
  { lng: -81.1537933349608, lat: 29.3727397918702 },
  { lng: -81.1539688110351, lat: 29.3828411102295 },
  { lng: -81.1554183959961, lat: 29.4016819000245 },
  { lng: -81.1553268432617, lat: 29.4039707183839 },
  { lng: -81.1557388305664, lat: 29.4076499938966 },
  { lng: -81.1556243896484, lat: 29.4108581542969 },
  { lng: -81.1482696533203, lat: 29.4115810394287 },
  { lng: -81.1481781005859, lat: 29.4143295288087 },
  { lng: -81.1491470336914, lat: 29.4166507720947 },
  { lng: -81.1301269531249, lat: 29.4216518402099 },
  { lng: -81.1227188110352, lat: 29.423749923706 },
  { lng: -81.1110763549805, lat: 29.4271125793458 },
  { lng: -81.1083755493163, lat: 29.4269886016846 }
];
